import React, { useState, useRef } from 'react'
import { Container, Row, Col, Alert } from 'react-bootstrap'
import lang from '../../assets/loadLanguage'
import GameList from '../../components/GameList'
import SelectedGameList from '../../components/SelectedGameList'
import FormHeader from '../../components/FormHeader'
import FormFooter from '../../components/FormFooter'
import EditingFooter from '../../components/EditingFooter'

export default function Unwanted({
  formValues,
  handlePrevStep,
  handleNextStep,
  activeStep,
  stepCount,
  makeRequest,
  editing = false,
  handleSave,
  handleCancel,
  games,
}) {
  const [unwanted, setUnwanted] = useState(formValues.unwanted)
  const [showAlert, setShowAlert] = useState(false)
  const selectedGamesRef = useRef(null)

  function addToUnwanted(game) {
    if (unwanted.length === 0)
      selectedGamesRef.current.scrollIntoView({ behavior: 'smooth' })
    if (
      unwanted.length >
      games.length - process.env.REACT_APP_MIN_ALLOWED_GAMES_COUNT
    ) {
      setShowAlert(true)
      setTimeout(() => setShowAlert(false), 3000)
    } else setUnwanted((prev) => [...prev, game])
  }
  function removeFromUnwanted(game) {
    const newUnwanted = [...unwanted]
    const index = unwanted.indexOf(game)
    newUnwanted.splice(index, 1)
    setUnwanted([...newUnwanted])
  }

  return (
    <>
      {showAlert && (
        <Alert className="fixed-top m-2 mt-3 text-center">
          {lang.unwantedCountLimit}
        </Alert>
      )}
      {!editing ? (
        <FormHeader
          handleNextStep={() => handleNextStep({ unwanted })}
          handlePrevStep={() => handlePrevStep({ unwanted })}
          activeStep={activeStep}
          stepCount={stepCount}
          editing={editing}
        />
      ) : (
        ''
      )}
      <Container className="text-center">
        <Row className="my-3">
          <h4>{lang.unwantedTitle}</h4>
        </Row>
        <Row>
          <Col />
          <Col sm={12} md={12} lg={10} xl={8} className="m-auto">
            <p>{lang.unwantedHelpText}</p>
          </Col>
          <Col />
        </Row>
        <Row ref={selectedGamesRef}>
          <SelectedGameList
            list={unwanted}
            onClick={removeFromUnwanted}
            background="danger"
            label={lang.unwantedLabel}
          />
        </Row>
        <GameList
          games={games}
          excludeList={unwanted.concat(formValues.wishlist)}
          onClick={addToUnwanted}
        />
      </Container>
      <div className="fixed-bottom">
        {!editing ? (
          <FormFooter
            handleNextStep={() => handleNextStep({ unwanted })}
            handlePrevStep={() => handlePrevStep({ unwanted })}
            activeStep={activeStep}
            stepCount={stepCount}
            makeRequest={makeRequest}
            editing={editing}
          />
        ) : (
          <EditingFooter
            handleSave={() => {
              handleSave({
                unwanted: unwanted,
              })
            }}
            handleCancel={handleCancel}
          />
        )}
      </div>
    </>
  )
}
