import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import lang from '../assets/loadLanguage'

export default function MessageModal({ message, show, toggleShow }) {
  return (
    <Modal show={show} onHide={toggleShow} animation={false}>
      <Modal.Header>
        <Modal.Title>{lang.orderDoneText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={toggleShow}>
          {lang.okText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
