import React from 'react'
import { Col, Figure, Row, Button } from 'react-bootstrap'
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'
import lang from '../assets/loadLanguage'
import logo from '../assets/logo.png'

export default function FormHeader({
  handleNextStep,
  handlePrevStep,
  activeStep,
  stepCount,
  editing,
  formik = false,
  final = false,
  disabledNav = false,
}) {
  return (
    <>
      <Row className="d-flex align-items-center justify-content-center">
        <Col
          xs="3"
          className="d-flex align-items-center justify-content-center"
        >
          {editing
            ? ''
            : activeStep > 0 && (
                <Button
                  onClick={handlePrevStep}
                  size="md"
                  variant="primary"
                  disabled={disabledNav}
                >
                  <ChevronLeft />
                </Button>
              )}
        </Col>
        <Col xs="6">
          <Row className="d-flex align-items-center justify-content-center">
            <Figure className="text-center mb-0">
              <Figure.Image width="100rem" alt="logo" src={logo} />
            </Figure>
          </Row>
          <Row className="progress w-100" style={{ maxHeight: '0.2rem' }}>
            <div
              className="progress-bar bg-primary"
              style={{ width: `${((activeStep + 1) * 100) / stepCount}%` }}
            />
          </Row>
        </Col>
        <Col
          xs="3"
          className="d-flex align-items-center justify-content-center"
        >
          {editing
            ? ''
            : !final && (
                <Button
                  onClick={formik ? () => {} : handleNextStep}
                  size="md"
                  type={formik ? 'submit' : 'button'}
                  variant="primary"
                >
                  <ChevronRight />
                </Button>
              )}
        </Col>
      </Row>
      {activeStep === 0 && (
        <Row>
          <Col />
          <Col
            sm={12}
            md={12}
            lg={10}
            xl={8}
            className="m-auto text-center mt-3"
          >
            <p>{lang.preferencesHelpText}</p>
          </Col>
          <Col />
        </Row>
      )}
    </>
  )
}
