import React, { useState } from 'react'
import FormHeader from '../../components/FormHeader'
import FormFooter from '../../components/FormFooter'
import {
  Row,
  Col,
  ToggleButton,
  ButtonGroup,
  Container,
  Card,
} from 'react-bootstrap'
import { Puzzle } from 'react-bootstrap-icons'
import lang from '../../assets/loadLanguage'
import EditingFooter from '../../components/EditingFooter'

export default function PreferencesLangPref({
  formValues,
  handlePrevStep,
  handleNextStep,
  activeStep,
  stepCount,
  makeRequest,
  editing = false,
  handleSave,
  handleCancel,
}) {
  const [coop, setCoop] = useState(formValues.coop)

  return (
    <>
      {!editing ? (
        <FormHeader
          handleNextStep={() => handleNextStep({ coop })}
          handlePrevStep={() => handlePrevStep({ coop })}
          activeStep={activeStep}
          stepCount={stepCount}
          editing={editing}
        />
      ) : (
        ''
      )}
      <Row>
        <Container>
          <Col />
          <Col sm={12} md={10} lg={8} xl={6} className="m-auto">
            <Card className="py-4 px-3 mt-4 shadow rounded text-center">
              <Row>
                <Puzzle size={40} />
              </Row>
              <Row className="my-3">
                <h4>{lang.coopTitle}</h4>
              </Row>
              <Row className="mt-2">
                <ButtonGroup className="mb-2" vertical>
                  <ToggleButton
                    className="m-1 unselected"
                    id="radio-coop-1"
                    type="radio"
                    variant={coop === '1' ? 'primary' : 'light'}
                    name="coop"
                    value={'1'}
                    onChange={() => setCoop('1')}
                  >
                    {lang.yesLabel}
                  </ToggleButton>
                  <ToggleButton
                    className="d-block m-1 unselected"
                    id="radio-coop-0"
                    type="radio"
                    variant={coop === '0' ? 'primary' : 'light'}
                    name="coop"
                    value={'0'}
                    onChange={() => setCoop('0')}
                  >
                    {lang.noLabel}
                  </ToggleButton>
                  <ToggleButton
                    className="d-block m-1 unselected"
                    id="radio-coop-2"
                    type="radio"
                    variant={coop === '2' ? 'primary' : 'light'}
                    name="coop"
                    value={'2'}
                    onChange={() => setCoop('2')}
                  >
                    {lang.nvmLabel}
                  </ToggleButton>
                </ButtonGroup>
              </Row>
            </Card>
          </Col>
          <Col />
        </Container>
      </Row>
      {!editing ? (
        <FormFooter
          handleNextStep={() => handleNextStep({ coop })}
          handlePrevStep={() => handlePrevStep({ coop })}
          activeStep={activeStep}
          stepCount={stepCount}
          makeRequest={makeRequest}
          editing={editing}
        />
      ) : (
        <EditingFooter
          handleSave={() => handleSave({ coop: coop })}
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}
