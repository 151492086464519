import React from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { PencilSquare } from 'react-bootstrap-icons'

export default function SummaryRow({ handleClick, children, disabledEdit }) {
  return (
    <Row>
      <Col>{children}</Col>
      <Col xs={2} className="d-flex mb-auto">
        <Button
          variant="primary"
          size="sm"
          disabled={disabledEdit}
          onClick={handleClick}
        >
          <PencilSquare />
        </Button>
      </Col>
    </Row>
  )
}
