/* eslint-disable react/jsx-props-no-spreading */

import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthContext from './AuthContext'
import api from './api'
import isEditDisabled from './isEditDisabled'

export default function PrivateRoute({ component: Component }) {
  const { state, dispatch } = useContext(AuthContext)

  function updateUser() {
    api
      .getUser()
      .then((data) => {
        dispatch({
          type: 'GET_USER',
          payload: {
            user: data[0],
            disabledEdit: isEditDisabled(data[0].dateNext),
          },
        })
      })
      .catch((error) => {
        dispatch({
          type: 'LOGOUT',
        })
        dispatch({
          type: 'SET_FEEDBACK',
          payload: {
            message: error.message,
            variant: 'danger',
          },
        })
      })
  }

  return (
    <Route>
      {state.isAuthenticated ? (
        <Component updateUser={updateUser} />
      ) : (
        <Redirect to="/login" />
      )}
    </Route>
  )
}
