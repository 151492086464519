import React, { useContext } from 'react'
import lang from '../../assets/loadLanguage'
import AuthContext from '../../utils/AuthContext'
import playerCountOptions from '../../assets/playerCountOptions'
import difficultyOptions from '../../assets/difficultyOptions'
import playTimeOptions from '../../assets/playTimeOptions'
import PreferencesPlayerCount from '../signup/PreferencesPlayerCount'
import PreferencesDifficulty from '../signup/PreferencesDifficulty'
import PreferencesPlayTime from '../signup/PreferencesPlayTime'
import PreferencesChildren from '../signup/PreferencesChildren'
import PreferencesTwoPlayer from '../signup/PreferencesTwoPlayer'
import PreferencesLangPref from '../signup/PreferencesLangPref'
import PreferencesCoop from '../signup/PreferencesCoop'
import PreferencesVideoRequired from '../signup/PreferencesVideoRequired'
import PreferencesFavourites from '../signup/PreferencesFavourites'
import Note from '../signup/Note'
import SummaryRow from '../../components/SummaryRow'
import SummaryCard from '../../components/SummaryCard'

export default function PreferencesSettings({ setEditTarget, handleSave, handleCancel }) {
  const { state } = useContext(AuthContext)
  const { user, disabledEdit } = state
  return (
    <SummaryCard title={lang.preferencesTitle}>
      <SummaryRow
        disabledEdit={disabledEdit}
        handleClick={() =>
          setEditTarget(
            <PreferencesPlayerCount
              formValues={user}
              editing={true}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          )
        }
      >
        <p>
          {lang.playerCountLabel}:
          <strong>
            {' '}
            {user.playerCount &&
              user.playerCount
                .map((value) => {
                  const option = playerCountOptions.find(
                    (option) => option.value === value
                  )
                  if (option) return option.label
                })
                .join(', ')}
          </strong>
        </p>
      </SummaryRow>
      <SummaryRow
        disabledEdit={disabledEdit}
        handleClick={() =>
          setEditTarget(
            <PreferencesDifficulty
              formValues={user}
              editing={true}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          )
        }
      >
        <p>
          {lang.difficultyLabel}:
          <strong>
            {' '}
            {user.difficulty &&
              user.difficulty
                .map((value) => {
                  const option = difficultyOptions.find(
                    (option) => option.value === value
                  )
                  if (option) return option.label
                })
                .join(', ')}
          </strong>
        </p>
      </SummaryRow>
      <SummaryRow
        disabledEdit={disabledEdit}
        handleClick={() =>
          setEditTarget(
            <PreferencesPlayTime
              formValues={user}
              editing={true}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          )
        }
      >
        <p>
          {lang.playTimeLabel}:
          <strong>
            {' '}
            {user.playTime &&
              user.playTime
                .map((value) => {
                  const option = playTimeOptions.find(
                    (option) => option.value === value
                  )
                  if (option) return option.label
                })
                .join(', ')}
          </strong>
        </p>
      </SummaryRow>
      <SummaryRow
        disabledEdit={disabledEdit}
        handleClick={() =>
          setEditTarget(
            <PreferencesChildren
              formValues={user}
              editing={true}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          )
        }
      >
        <p>
          {`${lang.childrenLabel}: `}
          <strong>
            {user.children === '1'
              ? user.childrenType === '1'
                ? `${lang.childrenTypeLabel1}, `
                : `${lang.childrenTypeLabel2}, `
              : lang.noLabel}
            {user.children === '1'
              ? user.childrenAge === '1'
                ? lang.childrenAgeLabel1
                : lang.childrenAgeLabel2
              : ''}
          </strong>
        </p>
      </SummaryRow>
      <SummaryRow
        disabledEdit={disabledEdit}
        handleClick={() =>
          setEditTarget(
            <PreferencesTwoPlayer
              formValues={user}
              editing={true}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          )
        }
      >
        <p>
          {`${lang.twoPlayerLabel}: `}
          <strong>
            {user.twoPlayer === '1' ? lang.yesLabel : lang.noLabel}
          </strong>
        </p>
      </SummaryRow>
      <SummaryRow
        disabledEdit={disabledEdit}
        handleClick={() =>
          setEditTarget(
            <PreferencesLangPref
              formValues={user}
              editing={true}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          )
        }
      >
        <p>
          {`${lang.langPrefLabel}: `}
          <strong>
            {user.langPref === '1' ? lang.yesLabel : lang.noLabel}
          </strong>
        </p>
      </SummaryRow>
      <SummaryRow
        disabledEdit={disabledEdit}
        handleClick={() =>
          setEditTarget(
            <PreferencesCoop
              formValues={user}
              editing={true}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          )
        }
      >
        <p>
          {`${lang.coopLabel}: `}
          <strong>
            {user.coop === '0' ? lang.noLabel : ''}
            {user.coop === '1' ? lang.yesLabel : ''}
            {user.coop === '2' ? lang.nvmLabel : ''}
          </strong>
        </p>
      </SummaryRow>
      <SummaryRow
        disabledEdit={disabledEdit}
        handleClick={() =>
          setEditTarget(
            <PreferencesVideoRequired
              formValues={user}
              editing={true}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          )
        }
      >
        <p>
          {`${lang.videoRequiredLabel} `}
          <strong>
            {user.videoRequired === '1' ? lang.yesLabel : lang.noLabel}
          </strong>
        </p>
      </SummaryRow>
      <SummaryRow
        disabledEdit={disabledEdit}
        handleClick={() =>
          setEditTarget(
            <PreferencesFavourites
              formValues={user}
              editing={true}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          )
        }
      >
        <p>
          {`${lang.favouritesLabel}: `}
          <strong>{user.favourites}</strong>
        </p>
      </SummaryRow>
      <SummaryRow
        disabledEdit={disabledEdit}
        handleClick={() =>
          setEditTarget(
            <Note
              formValues={user}
              editing={true}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          )
        }
      >
        <p>
          {`${lang.notesLabel}: `}
          <strong>{user.note}</strong>
        </p>
      </SummaryRow>
    </SummaryCard>
  )
}
