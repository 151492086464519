import React from 'react'
import { Row, Col, Container, Card, Figure } from 'react-bootstrap'
import { Envelope } from 'react-bootstrap-icons'
import lang from '../../assets/loadLanguage'
import logo from '../../assets/logo.png'
import { Link } from 'react-router-dom'

export default function Success({ activeStep, stepCount }) {
  return (
    <>
      <Row className="d-flex align-items-center justify-content-center">
        <Col xs="3"></Col>
        <Col xs="6">
          <Row className="d-flex align-items-center justify-content-center">
            <Figure className="text-center">
              <Figure.Image width="100rem" alt="logo" src={logo} />
            </Figure>
          </Row>
          <Row>
            <div className="progress w-100" style={{ maxHeight: '0.2rem' }}>
              <div
                className="progress-bar bg-primary"
                style={{ width: `${((activeStep + 1) * 100) / stepCount}%` }}
              />
            </div>
          </Row>
        </Col>
        <Col xs="3"></Col>
      </Row>
      <Row>
        <Container>
          <Col />
          <Col sm={12} md={10} lg={8} xl={6} className="m-auto">
            <Card className="py-4 px-3 mt-4 shadow rounded text-center">
              <Row>
                <Envelope size={40} />
              </Row>
              <Row className="mt-3">
                <h4>{lang.signupDoneTitle}</h4>
                <p>{lang.signupDoneText}</p>
              </Row>
              <Row className="mt-2">
                <Link to="/login">
                  <button
                    type="button"
                    className="btn btn-primary w-100 my-2 mx-auto"
                  >
                    {lang.goToLoginButtonText}
                  </button>
                </Link>
              </Row>
              <Row></Row>
            </Card>
          </Col>
          <Col />
        </Container>
      </Row>
    </>
  )
}
