/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import lang from '../assets/loadLanguage'
import PreferencesChildren from '../sections/signup/PreferencesChildren'
import PreferencesDifficulty from '../sections/signup/PreferencesDifficulty'
import PreferencesLangPref from '../sections/signup/PreferencesLangPref'
import PreferencesPlayerCount from '../sections/signup/PreferencesPlayerCount'
import PreferencesPlayTime from '../sections/signup/PreferencesPlayTime'
import PreferencesTwoPlayer from '../sections/signup/PreferencesTwoPlayer'
import PreferencesCoop from '../sections/signup/PreferencesCoop'
import Product from '../sections/signup/Product'
import PreferencesVideoRequired from '../sections/signup/PreferencesVideoRequired'
import PreferencesFavourites from '../sections/signup/PreferencesFavourites'
import Wishlist from '../sections/signup/Wishlist'
import Unwanted from '../sections/signup/Unwanted'
import Note from '../sections/signup/Note'
import Address from '../sections/signup/Address'
import Success from '../sections/signup/Success'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import api from '../utils/api'

export default function Signup() {
  const [formValues, setFormValues] = useState({
    playerCount: [],
    difficulty: [],
    playTime: [],
    favourites: '',
    coop: '2',
    twoPlayer: '0',
    langPref: '0',
    children: '0',
    childrenType: '0',
    childrenAge: '0',
    videoRequired: '1',
    note: '',
    courier: 'Foxpost',
    foxpostMachine: {
      label: lang.foxpostPlaceholder,
      value: '',
    },
    wishlist: [],
    unwanted: [],
    productid: '3',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    postcode: '',
    city: '',
    addr1: '',
    addr2: '',
    addrNote: '',
    password: '',
    passwordConfirm: '',
    source: '',
    coupon: '',
    newsletter: false,
    acceptTerms: false,
  })
  const [activeStep, setActiveStep] = useState(0)
  const stepCount = 15

  //fetching games from API
  const [games, setGames] = useState([])
  useEffect(() => {
    api
      .getGames()
      .then((data) => setGames(data[0]))
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const handleNextStep = (newData) => {
    setFormValues((prev) => ({ ...prev, ...newData }))
    setActiveStep((prev) => prev + 1)
  }

  const handlePrevStep = (newData) => {
    setFormValues((prev) => ({ ...prev, ...newData }))
    setActiveStep((prev) => prev - 1)
  }

  const steps = [
    <PreferencesPlayerCount
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
      activeStep={activeStep}
      stepCount={stepCount}
      formValues={formValues}
    />,
    <PreferencesDifficulty
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
      activeStep={activeStep}
      stepCount={stepCount}
      formValues={formValues}
    />,
    <PreferencesPlayTime
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
      activeStep={activeStep}
      stepCount={stepCount}
      formValues={formValues}
    />,
    <PreferencesChildren
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
      activeStep={activeStep}
      stepCount={stepCount}
      formValues={formValues}
    />,
    <PreferencesTwoPlayer
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
      activeStep={activeStep}
      stepCount={stepCount}
      formValues={formValues}
    />,
    <PreferencesLangPref
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
      activeStep={activeStep}
      stepCount={stepCount}
      formValues={formValues}
    />,
    <PreferencesCoop
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
      activeStep={activeStep}
      stepCount={stepCount}
      formValues={formValues}
    />,
    <PreferencesVideoRequired
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
      activeStep={activeStep}
      stepCount={stepCount}
      formValues={formValues}
    />,
    <PreferencesFavourites
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
      activeStep={activeStep}
      stepCount={stepCount}
      formValues={formValues}
    />,
    <Wishlist
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
      activeStep={activeStep}
      stepCount={stepCount}
      formValues={formValues}
      games={games}
    />,
    <Unwanted
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
      activeStep={activeStep}
      stepCount={stepCount}
      formValues={formValues}
      games={games}
    />,
    <Note
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
      activeStep={activeStep}
      stepCount={stepCount}
      formValues={formValues}
    />,
    <Product
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
      activeStep={activeStep}
      stepCount={stepCount}
      formValues={formValues}
    />,
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <Address
        handleNextStep={handleNextStep}
        handlePrevStep={handlePrevStep}
        activeStep={activeStep}
        stepCount={stepCount}
        formValues={formValues}
      />
    </GoogleReCaptchaProvider>,
    <Success activeStep={activeStep} stepCount={stepCount} />,
  ]

  return <Container>{steps[activeStep]}</Container>
}
