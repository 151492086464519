import React, { useContext } from 'react'
import { Card, Col, Container, Row, Button, Alert } from 'react-bootstrap'
import {
  EmojiFrown,
  EmojiHeartEyes,
  EmojiNeutral,
  EmojiSmile,
  Flag,
} from 'react-bootstrap-icons'
import lang from '../assets/loadLanguage'
import api from '../utils/api'
import AuthContext from '../utils/AuthContext'

export default function GameCard({
  options,
  game,
  showKeepItModal,
  showReportGameModal,
  updateUser,
}) {
  const { state, dispatch } = useContext(AuthContext)
  const { user } = state
  const { userRatingSet } = api

  function isGameSold(game) {
    if (game.status === 'sold') {
      return (
        <Alert className="m-1 my-2 text-center" variant="success">
          <small>{lang.gameStatusSold}</small>
        </Alert>
      )
    }
    if (game.status === 'sold_pending') {
      return (
        <Alert className="m-1 my-2 text-center" variant="warning">
          <small>{lang.gameStatusSoldPending}</small>
        </Alert>
      )
    }
    return false
  }

  function getRating(code) {
    const rating = user.userRatingList.find((item) => item.gamecode === code)
    if (rating) {
      return parseInt(rating.rating, 10)
    }
    return ''
  }

  function handleRatingChange(rating, gamecode) {
    dispatch({
      type: 'SET_RATING',
      payload: {
        gamecode: gamecode,
        rating: rating,
      },
    })
    userRatingSet({
      gamecode: gamecode,
      rating: rating,
    })
      .then(() => updateUser())
      .catch((error) => {
        dispatch({
          type: 'LOGOUT',
        })
        dispatch({
          type: 'SET_FEEDBACK',
          payload: {
            message: error.message,
            variant: 'danger',
          },
        })
      })
  }

  return (
    <>
      <Col xs={12} md={6} lg={4} className="my-2">
        <Card
          className="p-2 shadow"
          style={options ? { minHeight: '26rem' } : { minHeight: '20rem' }}
        >
          <Container>
            <Row style={{ minHeight: '3rem' }}>
              <h6 className="m-auto text-center">
                {game.name}{' '}
                <span className="fw-normal small">
                  ({game.id}-{game.code})
                </span>
              </h6>
            </Row>
            <Row className="my-auto">
              <img
                src={`./game-icons/${game.code}.jpg`}
                style={{
                  objectFit: 'contain',
                  height: '10rem',
                  width: '10rem',
                }}
                className="m-auto p-2"
                alt=""
              />
            </Row>
            <h6 className="mt-3 w-100 text-center">{lang.ratingLabel}</h6>
            <Row className="justify-content-between m-auto">
              <Col xs={3} className="p-0 text-center">
                <Button
                  variant={
                    getRating(game.code) === 0
                      ? 'outline-secondary shadow nohover'
                      : 'link'
                  }
                  onClick={() => handleRatingChange(0, game.code)}
                >
                  <EmojiFrown style={{ color: 'grey' }} size={26} />
                </Button>
              </Col>
              <Col xs={3} className="p-0 text-center">
                <Button
                  variant={
                    getRating(game.code) === 1
                      ? 'outline-secondary shadow nohover'
                      : 'link'
                  }
                  onClick={() => handleRatingChange(1, game.code)}
                >
                  <EmojiNeutral style={{ color: '#c09d29' }} size={26} />
                </Button>
              </Col>
              <Col xs={3} className="p-0 text-center">
                <Button
                  variant={
                    getRating(game.code) === 2
                      ? 'outline-secondary shadow nohover'
                      : 'link'
                  }
                  onClick={() => handleRatingChange(2, game.code)}
                >
                  <EmojiSmile style={{ color: '#429258' }} size={26} />
                </Button>
              </Col>
              <Col xs={3} className="p-0 text-center">
                <Button
                  variant={
                    getRating(game.code) === 3
                      ? 'outline-secondary shadow nohover'
                      : 'link'
                  }
                  onClick={() => handleRatingChange(3, game.code)}
                >
                  <EmojiHeartEyes style={{ color: '#13722c' }} size={26} />
                </Button>
              </Col>
            </Row>
            {options &&
              (isGameSold(game) ? (
                isGameSold(game)
              ) : (
                <>
                  <Row className="mt-3">
                    {game.price > 0 ? (
                      <Button
                        className="w-100 text-center"
                        variant="primary"
                        onClick={() => showKeepItModal(game)}
                        disabled={!(game.price > 0)}
                      >
                        {`${lang.keepItButtonText} - ${game.price} ${lang.priceTag}`}
                      </Button>
                    ) : (
                      <Alert variant="info" className="mb-0 p-2 text-center">
                        {lang.disabledKeepItInfo}
                      </Alert>
                    )}
                  </Row>
                  <Row>
                    <Button
                      className="w-100 text-center text-decoration-none"
                      variant="link"
                      onClick={() => showReportGameModal(game)}
                    >
                      <Flag /> {lang.reportButtonText}
                    </Button>
                  </Row>
                </>
              ))}
          </Container>
        </Card>
      </Col>
    </>
  )
}
