import React, { useReducer } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Login from './pages/Login'
import PrivateRoute from './utils/PrivateRoute'
import Dashboard from './pages/Dashboard'
import { initialState, reducer } from './utils/reducer'
import AuthContext from './utils/AuthContext'
import Shop from './pages/Shop'
import Signup from './pages/Signup'
import Settings from './pages/Settings'
import ForgottenPassword from './pages/ForgottenPassword'
import ResetPassword from './pages/ResetPassword'
import OrderCoupon from './pages/OrderCoupon'

export default function App() {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <Router>
      <AuthContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <Route path="/forgotten-password" component={ForgottenPassword} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route path="/order-coupon" component={OrderCoupon} />
          <PrivateRoute path="/shop" component={Shop} />
          <PrivateRoute path="/settings" component={Settings} />
          <PrivateRoute path="/" component={Dashboard} />
        </Switch>
      </AuthContext.Provider>
    </Router>
  )
}
