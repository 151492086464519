import React, { useState } from 'react'
import { Card, Col, Form, Row, Container } from 'react-bootstrap'
import { PlayBtnFill } from 'react-bootstrap-icons'

export default function GameList({ games, excludeList, onClick, shop }) {
  const [searchTerm, setSearchTerm] = useState('')

  function handleSearchChange(event) {
    setSearchTerm(event.currentTarget.value)
  }
  return (
    <Container className="pb-5">
      <Row>
        <Col xs={10} md={8} lg={6} className="mx-auto">
          <Form.Control
            type="text"
            placeholder="Keresés..."
            onChange={handleSearchChange}
            className="my-3"
          />
        </Col>
      </Row>
      <Row style={{ minHeight: '12rem' }} className="pb-5">
        {games.map((game) => {
          if (
            excludeList &&
            JSON.stringify(excludeList).includes(JSON.stringify(game))
          )
            return
          if (
            searchTerm.length < 1 ||
            game.name?.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return (
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className="my-2 px-1"
                key={game.code}
              >
                <Card
                  className="p-1 shadow"
                  onClick={() => (onClick ? onClick(game) : '')}
                  style={{ minHeight: shop ? '19rem' : '15rem' }}
                >
                  <Container
                    className="d-flex flex-column"
                    style={{ minHeight: shop ? '18rem' : '14rem' }}
                  >
                    <Row>
                      <h6 className="p-2 w-100 text-center">{game.name}</h6>
                    </Row>
                    <Row className="my-auto">
                      <img
                        src={`./game-icons/${game.code}.jpg`}
                        style={{
                          objectFit: 'contain',
                          width: '100%',
                          maxHeight: '9rem',
                          maxWidth: '10rem',
                        }}
                        className="m-auto"
                      />
                    </Row>
                    {!shop && game.hasVideo === 1
                      ? (
                        <div className="text-end">
                          <PlayBtnFill color="#4AE875" />
                        </div>
                      ): ''}
                    {shop && <Row>{shop.purchaseButton(game)}</Row>}
                  </Container>
                </Card>
              </Col>
            )
          }
        })}
      </Row>
    </Container>
  )
}
