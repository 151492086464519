import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import lang from '../../assets/loadLanguage'
import FormHeader from '../../components/FormHeader'
import FormFooter from '../../components/FormFooter'
import { Row, Container, Col, Card, Alert } from 'react-bootstrap'
import * as Yup from 'yup'
import { CardChecklist } from 'react-bootstrap-icons'
import api from '../../utils/api'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import FocusError from '../../utils/FocusError'

const signupSchema = Yup.object().shape({
  email: Yup.string().email(lang.invalidEmail).required(lang.missingValueText),
  password: Yup.string()
    .min(5, lang.invalidPasswordLength)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])/, lang.invalidPasswordFormat)
    .required(lang.missingValueText),
  passwordConfirm: Yup.string()
    .min(5, lang.invalidPasswordLength)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])/, lang.invalidPasswordFormat)
    .required(lang.missingValueText)
    .oneOf([Yup.ref('password'), null], lang.passwordsDontMatchText),
  firstName: Yup.string().required(lang.missingValueText),
  lastName: Yup.string().required(lang.missingValueText),
  phone: Yup.string()
    .required(lang.missingValueText)
    .matches(/^\d+$/, lang.invalidFormat)
    .min(9, lang.invalidFormat)
    .max(9, lang.invalidFormat),
  postcode: Yup.string()
    .required(lang.missingValueText)
    .matches(/^\d+$/, lang.invalidFormat)
    .min(4, lang.invalidFormat)
    .max(4, lang.invalidFormat),
  city: Yup.string().required(lang.missingValueText),
  addr1: Yup.string().required(lang.missingValueText),
  acceptTerms: Yup.boolean().oneOf([true], lang.missingValueText),
})

function InputField({ touched, errors, fieldName, pw = false }) {
  return (
    <div className="form-group mb-2">
      <label htmlFor={fieldName}>{lang[fieldName + 'Label']}</label>
      <Field
        type={pw ? 'password' : 'text'}
        name={fieldName}
        className={`form-control ${
          touched[fieldName] && errors[fieldName] ? 'is-invalid' : ''
        }`}
      />
      <ErrorMessage
        component="div"
        name={fieldName}
        className="invalid-feedback"
      />
    </div>
  )
}

export default function Address({
  formValues,
  handlePrevStep,
  handleNextStep,
  activeStep,
  stepCount,
  makeRequest,
  editing = false,
}) {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [submitError, setSubmitError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  async function handleSubmit(values) {
    setSubmitError('')
    setSubmitting(true)
    if (executeRecaptcha) {
      const user = convertFormValuesToDB({ ...formValues, ...values })
      user.tokenCaptcha = await executeRecaptcha('signup')

      await api
        .userCreate(user)
        .then(() => {
          handleNextStep({ ...formValues, ...values })
        })
        .catch((error) => {
          setSubmitError(error.message)
        })
    } else {
      console.log('Google recaptcha not yet available')
      setSubmitError(lang.genericError)
    }
    setSubmitting(false)
  }

  function convertFormValuesToDB(formValues) {
    const formData = { ...formValues }
    delete formData.passwordConfirm
    delete formData.acceptTerms
    formData.foxpostMachine = formData.foxpostMachine.value
      ? formData.foxpostMachine.value
      : ''
    return formData
  }

  return (
    <Formik
      initialValues={formValues}
      onSubmit={handleSubmit}
      validationSchema={signupSchema}
    >
      {({ values, touched, errors }) => (
        <Form noValidate>
          <FocusError />
          <FormHeader
            handlePrevStep={() => handlePrevStep({ ...formValues, ...values })}
            activeStep={activeStep}
            stepCount={stepCount}
            editing={editing}
            formik={true}
            final={true}
            disabledNav={submitting}
          />
          <Row>
            <Container>
              <Col />
              <Col sm={12} md={10} lg={8} xl={6} className="m-auto">
                <Card className="py-4 px-3 mt-4 shadow rounded">
                  <Row>
                    <CardChecklist size={40} />
                  </Row>
                  <Row className="mt-3 text-center">
                    <h4>{lang.addressTitle}</h4>
                  </Row>
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="email"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="password"
                    pw={true}
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="passwordConfirm"
                    pw={true}
                  />
                </Card>
                <Card className="py-4 px-3 mt-4 shadow rounded">
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="lastName"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="firstName"
                  />
                  <div className="form-group mb-2">
                    <label htmlFor="phone">{lang.phoneLabel}</label>
                    <Row>
                      <Col className="pe-0" style={{ maxWidth: '4rem' }}>
                        <span
                          className="input-group-text ps-1"
                          id="phone-prefix"
                        >
                          {lang.phonePrefix}
                        </span>
                      </Col>
                      <Col className="ps-0">
                        <Field
                          type="tel"
                          name="phone"
                          className={`form-control w-100 ${
                            touched.phone && errors.phone ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="phone"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                  </div>
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="postcode"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="city"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="addr1"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="addr2"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="addrNote"
                  />
                </Card>
                <Card className="py-4 px-3 mt-4 shadow rounded">
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="coupon"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="source"
                  />
                  <Row className="mt-2">
                    <label>
                      <Field type="checkbox" name="newsletter" />
                      <span className="ms-2">{`${lang.newsletterLabel}`}</span>
                    </label>
                    <label>
                      <Field type="checkbox" name="acceptTerms" />
                      <span className="ms-2">{`${lang.acceptLabel}`}</span>
                      <a
                        href={process.env.REACT_APP_TERMS_LINK}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>{`${lang.termsLinkLabel}`}</span>
                      </a>
                    </label>
                    {touched.acceptTerms && errors.acceptTerms && (
                      <div className="invalid-feedback d-block">
                        {lang.mustAcceptTerms}
                      </div>
                    )}
                  </Row>
                </Card>
                {submitError && (
                  <Alert variant="danger" className="mt-3">
                    {submitError}
                  </Alert>
                )}
              </Col>
              <Col />
            </Container>
          </Row>
          <FormFooter
            handleNextStep={handleSubmit}
            handlePrevStep={() => handlePrevStep({ ...formValues, ...values })}
            activeStep={activeStep}
            stepCount={stepCount}
            makeRequest={makeRequest}
            editing={editing}
            formik={true}
            final={true}
            disabled={submitting}
          />
          <Row className="my-5"></Row>
        </Form>
      )}
    </Formik>
  )
}
