/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'
import {
  ButtonGroup,
  Col,
  Container,
  Row,
  ToggleButton,
  Card,
  Alert,
  Figure,
} from 'react-bootstrap'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import lang from '../assets/loadLanguage'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Envelope, Gift } from 'react-bootstrap-icons'
import FocusError from '../utils/FocusError'
import FormFooter from '../components/FormFooter'
import logo from '../assets/logo.png'
import api from '../utils/api'

const signupSchema = Yup.object().shape({
  email: Yup.string().email(lang.invalidEmail).required(lang.missingValueText),
  firstName: Yup.string().required(lang.missingValueText),
  lastName: Yup.string().required(lang.missingValueText),
  postcode: Yup.string()
    .required(lang.missingValueText)
    .matches(/^\d+$/, lang.invalidFormat)
    .min(4, lang.invalidFormat)
    .max(4, lang.invalidFormat),
  city: Yup.string().required(lang.missingValueText),
  addr1: Yup.string().required(lang.missingValueText),
  acceptTerms: Yup.boolean().oneOf([true], lang.missingValueText),
})

function InputField({ touched, errors, fieldName, pw = false }) {
  return (
    <div className="form-group mb-2">
      <label htmlFor={fieldName}>{lang[fieldName + 'Label']}</label>
      <Field
        type={pw ? 'password' : 'text'}
        name={fieldName}
        className={`form-control ${
          touched[fieldName] && errors[fieldName] ? 'is-invalid' : ''
        }`}
      />
      <ErrorMessage
        component="div"
        name={fieldName}
        className="invalid-feedback"
      />
    </div>
  )
}

function ProductSelector({ productid, setProductid }) {
  return (
    <ButtonGroup className="mb-2 w-100">
      <Container className="p-1">
        <Row className="mt-2">
          <p className="text-center">{lang.couponDescription}</p>
        </Row>
        <Row>
          <Col xs={6} className="p-1">
            <ToggleButton
              className="w-100 p-1 unselected product"
              id="radio-productid-5"
              type="radio"
              variant={productid === '5' ? 'primary' : 'light'}
              name="productid"
              value={'5'}
              onChange={() => {
                setProductid('5')
              }}
            >
              <span className="fs-4 d-block">{lang.onePackLabel}</span>
              <span className="fs-4 d-block">{lang.onePackPrice}</span>
              <span className="d-block">{lang.onePackLength}</span>
            </ToggleButton>
          </Col>
          <Col xs={6} className="p-1">
            <ToggleButton
              className="w-100 pt-1 unselected product"
              id="radio-productid-3"
              type="radio"
              variant={productid === '3' ? 'primary' : 'light'}
              name="productid"
              value={'3'}
              onChange={() => setProductid('3')}
            >
              <span className="fs-4 d-block">{lang.threePackLabel}</span>
              <span className="fs-4 d-block">{lang.threePackPrice}</span>
              <span className="d-block">{lang.threePackLength}</span>
            </ToggleButton>
          </Col>
        </Row>
      </Container>
    </ButtonGroup>
  )
}

function SuccessPage() {
  return (
    <Row>
      <Container>
        <Col />
        <Col sm={12} md={10} lg={8} xl={6} className="m-auto">
          <Card className="py-4 px-3 mt-4 shadow rounded text-center">
            <Row>
              <Envelope size={40} />
            </Row>
            <Row className="mt-3">
              <h4>{lang.couponOrderDoneTitle}</h4>
              <p>{lang.couponOrderDoneText}</p>
            </Row>
            <Row></Row>
          </Card>
        </Col>
        <Col />
      </Container>
    </Row>
  )
}

function OrderForm() {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [formValues, setFormValues] = useState({
    productid: '3',
    email: '',
    firstName: '',
    lastName: '',
    postcode: '',
    city: '',
    addr1: '',
    acceptTerms: false,
  })

  useEffect(() => {
    console.log(formValues)
  }, [formValues])

  const handleSubmit = async (values) => {
    setSubmitError('')
    setSubmitting(true)
    values.productid = formValues.productid
    if (executeRecaptcha) {
      values.tokenCaptcha = await executeRecaptcha('orderCoupon')
      console.log(values)
      await api
        .orderCoupon(values)
        .then(() => {
          console.log('success')
          setSuccess(true)
        })
        .catch((error) => {
          setSubmitError(error.message)
        })
    } else {
      console.log('Google recaptcha not yet available')
      setSubmitError(lang.genericError)
    }
    setSubmitting(false)
  }

  const [submitError, setSubmitError] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)

  return success ? (
    <SuccessPage />
  ) : (
    <Formik
      initialValues={formValues}
      onSubmit={handleSubmit}
      validationSchema={signupSchema}
    >
      {({ touched, errors }) => (
        <Form>
          <FocusError />
          <Row>
            <Container>
              <Col />
              <Col sm={12} md={10} lg={8} xl={6} className="m-auto">
                <Card className="py-4 px-3 mt-4 shadow rounded">
                  <Row>
                    <Gift size={40} />
                  </Row>
                  <Row className="mt-3 text-center">
                    <h4>{lang.addressTitleCoupon}</h4>
                  </Row>
                  <ProductSelector
                    productid={formValues.productid}
                    setProductid={(productid) =>
                      setFormValues({ ...formValues, productid: productid })
                    }
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="email"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="lastName"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="firstName"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="postcode"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="city"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="addr1"
                  />
                  <label>
                    <Field type="checkbox" name="acceptTerms" />
                    <span className="ms-2">{`${lang.acceptLabel}`}</span>
                    <a
                      href={process.env.REACT_APP_TERMS_LINK}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>{`${lang.termsLinkLabel}`}</span>
                    </a>
                  </label>
                  {touched.acceptTerms && errors.acceptTerms && (
                    <div className="invalid-feedback d-block">
                      {lang.mustAcceptTerms}
                    </div>
                  )}
                  {submitError && (
                    <Alert variant="danger" className="mt-3">
                      {submitError}
                    </Alert>
                  )}
                </Card>
              </Col>
              <Col />
            </Container>
          </Row>
          <FormFooter
            handleNextStep={handleSubmit}
            makeRequest={handleSubmit}
            formik={true}
            final={true}
            disabled={submitting}
          />
          <Row className="my-5"></Row>
        </Form>
      )}
    </Formik>
  )
}

export default function OrderCoupon() {
  return (
    <Container>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
      >
        <Row className="d-flex align-items-center justify-content-center">
          <Col></Col>
          <Col xs="6">
            <Row className="d-flex align-items-center justify-content-center">
              <Figure className="text-center mb-0">
                <Figure.Image width="100rem" alt="logo" src={logo} />
              </Figure>
            </Row>
          </Col>
          <Col xs="3"></Col>
        </Row>
        <OrderForm />
      </GoogleReCaptchaProvider>
    </Container>
  )
}
