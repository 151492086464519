import lang from './loadLanguage'

const playTimeOptions = [
  {
    value: '1',
    label: lang.playTimeOptionLabel1,
  },
  {
    value: '2',
    label: lang.playTimeOptionLabel2,
  },
  {
    value: '3',
    label: lang.playTimeOptionLabel3,
  },
  {
    value: '4',
    label: lang.playTimeOptionLabel4,
  },
]

export default playTimeOptions
