import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row, Alert } from 'react-bootstrap'
import lang from '../assets/loadLanguage'
import Feedback from '../sections/Feedback'
import Navigation from '../sections/Navigation'
import AuthContext from '../utils/AuthContext'
import { InfoCircleFill } from 'react-bootstrap-icons'
import api from '../utils/api'
import AddPack from '../sections/settings/AddPack'
import WishlistSettings from '../sections/settings/WishlistSettings'
import UnwantedSettings from '../sections/settings/UnwantedSettings'
import PreferencesSettings from '../sections/settings/PreferencesSettings'
import AddressSettings from '../sections/settings/AddressSettings'
import MessageModal from '../components/MessageModal'

export default function Settings({ updateUser }) {
  const { dispatch } = useContext(AuthContext)
  const [editTarget, setEditTarget] = useState(null)
  const [messageModal, setMessageModal] = useState(false)
  function toggleMessageModal() {
    setMessageModal((messageModal) => !messageModal)
  }

  useEffect(() => {
    updateUser()
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [editTarget])

  async function handleSave(updatedData) {
    setEditTarget(null)
    await api
      .userUpdate(updatedData)
      .then(() => updateUser())
      .catch((error) => {
        dispatch({
          type: 'LOGOUT',
        })
        dispatch({
          type: 'SET_FEEDBACK',
          payload: {
            message: error.message,
            variant: 'danger',
          },
        })
      })
  }
  function handleCancel() {
    setEditTarget(null)
  }

  function Header() {
    return (
      <Row className="text-center">
        <h1 className="my-3">{lang.settingsTitleText}</h1>
        <Feedback />
        <Container>
          <Alert variant="info">
            <InfoCircleFill className="me-2" />
            {lang.settingsInfo}
          </Alert>
        </Container>
      </Row>
    )
  }

  return (
    <>
      <Navigation />
      <Container>
        <MessageModal
          message={lang.addPackConfirmMessage}
          show={messageModal}
          toggleShow={toggleMessageModal}
        />
        {editTarget || (
          <>
            <Header />
            <Row className="mb-5">
              <Col sm={12} md={6}>
                <WishlistSettings
                  setEditTarget={setEditTarget}
                  handleSave={handleSave}
                  handleCancel={handleCancel}
                />
                <UnwantedSettings
                  setEditTarget={setEditTarget}
                  handleSave={handleSave}
                  handleCancel={handleCancel}
                />
                <PreferencesSettings
                  setEditTarget={setEditTarget}
                  handleSave={handleSave}
                  handleCancel={handleCancel}
                />
              </Col>
              <Col sm={12} md={6}>
                <AddPack toggleMessageModal={toggleMessageModal} />
                <AddressSettings
                  setEditTarget={setEditTarget}
                  handleSave={handleSave}
                  handleCancel={handleCancel}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  )
}
