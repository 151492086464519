import React, { useContext, useState, useEffect } from 'react'
import { Navbar, Nav, Button, Row, Col, Image } from 'react-bootstrap'
import { useHistory, Link } from 'react-router-dom'
import logo from '../assets/logo.png'
import AuthContext from '../utils/AuthContext'
import lang from '../assets/loadLanguage'

export default function Navigation() {
  const history = useHistory()
  const { dispatch } = useContext(AuthContext)
  const [page, setPage] = useState('/')
  useEffect(() => {
    setPage(window.location.pathname)
  }, [window.location.pathname])

  async function handleLogout() {
    dispatch({
      type: 'LOGOUT',
    })
    history.push('/login')
  }

  return (
    <Navbar bg="none" expand="md" className="px-2 mb-0 pb-0">
      <Navbar.Brand as={Link} to="/">
        <Row>
          <Col>
            <Image className="align-self-center mx-2" src={logo} width={70} />
          </Col>
        </Row>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-start text-center"
      >
        <Nav>
          <Link
            to="/"
            className={`nav-link text-center ${
              page === '/' ? 'text-primary' : ''
            }`}
          >
            {lang.homeButtonText}
          </Link>
        </Nav>
        <Nav>
          <Link
            to="/settings"
            className={`nav-link text-center ${
              page === '/settings' ? 'text-primary' : ''
            }`}
          >
            {lang.settingsButtonText}
          </Link>
        </Nav>
        <Nav>
          <Link
            to="/shop"
            className={`nav-link text-center ${
              page === '/shop' ? 'text-primary' : ''
            }`}
          >
            {lang.shopButtonText}
          </Link>
        </Nav>
        <Button
          variant="outline-primary"
          className="ms-auto"
          onClick={handleLogout}
        >
          {lang.logoutButtonText}
        </Button>
      </Navbar.Collapse>
    </Navbar>
  )
}
