import React, { useState, useContext } from 'react'
import { Col, Row, Card } from 'react-bootstrap'
import lang from '../../assets/loadLanguage'
import api from '../../utils/api'
import AuthContext from '../../utils/AuthContext'
import GameCard from '../../components/GameCard'
import KeepItModal from '../../components/KeepItModal'
import ReportGameModal from '../../components/ReportGameModal'

export default function MyGames({ updateUser }) {
  const { dispatch, state } = useContext(AuthContext)
  const { user } = state
  const activeUser = user.status === 'active' && user.dateNext
  const returningUserFirstPack =
    user.packageCountCurrent === '0' && user.packageCount !== '0'
  const { gameSellUsed, userPackageArrive } = api
  const currentPackage =
    activeUser && !returningUserFirstPack
      ? user.packageList[user.packageList.length - 1] || {}
      : {}
  const currentGames = currentPackage.games || []

  //creating previous games list
  const previousGames = []
  user.packageList.forEach((pack, index) => {
    if (activeUser && !returningUserFirstPack) {
      if (index !== user.packageList.length - 1) {
        pack.games.forEach((game) => previousGames.push(game))
      }
    } else {
      pack.games.forEach((game) => previousGames.push(game))
    }
  })

  const [keepItModal, setKeepItModal] = useState(false)
  const [reportGameModal, setReportGameModal] = useState(false)

  const closeKeepItModal = () => setKeepItModal(false)
  const showKeepItModal = (game) => setKeepItModal(game)

  const closeReportGameModal = () => setReportGameModal(false)
  const showReportGameModal = (game) => setReportGameModal(game)

  function setDateArrive(pack) {
    userPackageArrive(pack)
      .then(() => updateUser())
      .catch((error) => {
        dispatch({
          type: 'LOGOUT',
        })
        dispatch({
          type: 'SET_FEEDBACK',
          payload: {
            message: error.message,
            variant: 'danger',
          },
        })
      })
  }

  function List({ games, options = false }) {
    return (
      <Row className="justify-content-md-center">
        {games.map((game) => (
          <GameCard
            game={game}
            options={options}
            showKeepItModal={showKeepItModal}
            showReportGameModal={showReportGameModal}
            updateUser={updateUser}
            key={`${game.code}-${game.id}`}
          />
        ))}
      </Row>
    )
  }

  return (
    <>
      {keepItModal && (
        <KeepItModal
          game={keepItModal}
          gameSellUsed={gameSellUsed}
          updateUser={updateUser}
          keepItModal={keepItModal}
          closeKeepItModal={closeKeepItModal}
        />
      )}
      {reportGameModal && (
        <ReportGameModal
          game={reportGameModal}
          closeReportGameModal={closeReportGameModal}
          reportGameModal={reportGameModal}
        />
      )}
      <Row>
        <h3 className="mb-3 text-center">{lang.currentPackTitle}</h3>
      </Row>
      {activeUser ? (
        <>
          {currentPackage.dateArrive ? (
            <List games={currentGames} options />
          ) : (
            <Card className="shadow text-center p-3 my-4">
              <p>{lang.packDeliveredText}</p>
              <Col sm={8} md={4} className="m-auto">
                <button
                  className="btn btn-primary btn-lg m-auto"
                  onClick={() => setDateArrive(currentPackage)}
                  disabled={user.packageList.length === 0 || returningUserFirstPack}
                >
                  {lang.arrivedButtonText}
                </button>
              </Col>
            </Card>
          )}
        </>
      ) : (
        ''
      )}
      <Row>
        <h3 className="mt-5 mb-3 text-center">{lang.previousGamesTitle}</h3>
      </Row>
      <List games={previousGames} />
    </>
  )
}
