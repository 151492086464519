import React, { useState } from 'react'
import FormToggleButtonGroup from '../../components/FormToggleButtonGroup'
import lang from '../../assets/loadLanguage'
import playTimeOptions from '../../assets/playTimeOptions'
import FormHeader from '../../components/FormHeader'
import FormFooter from '../../components/FormFooter'
import { Row } from 'react-bootstrap'
import { Clock } from 'react-bootstrap-icons'
import EditingFooter from '../../components/EditingFooter'

export default function PreferencesPlayTime({
  formValues,
  handlePrevStep,
  handleNextStep,
  activeStep,
  stepCount,
  makeRequest,
  editing = false,
  handleSave,
  handleCancel,
}) {
  function getIcon() {
    return <Clock size={40} />
  }

  const [currentValue, setCurrentValue] = useState(formValues.playTime)

  function handleChange(e) {
    if (e.target.checked) {
      setCurrentValue((prev) => [...prev, e.target.value].sort())
    } else {
      setCurrentValue((prev) => [
        ...prev.filter((item) => item != e.target.value),
      ])
    }
  }

  return (
    <>
      {!editing ? (
        <FormHeader
          handleNextStep={() => handleNextStep({ playTime: currentValue })}
          handlePrevStep={() => handlePrevStep({ playTime: currentValue })}
          activeStep={activeStep}
          stepCount={stepCount}
          editing={editing}
        />
      ) : (
        ''
      )}
      <Row>
        <FormToggleButtonGroup
          currentValue={currentValue}
          handleChange={handleChange}
          options={playTimeOptions}
          title={lang.playTimeTitle}
          helpText={lang.multiChoiceHelpText}
          getIcon={getIcon}
        />
      </Row>
      {!editing ? (
        <FormFooter
          handleNextStep={() => handleNextStep({ playTime: currentValue })}
          handlePrevStep={() => handlePrevStep({ playTime: currentValue })}
          activeStep={activeStep}
          stepCount={stepCount}
          makeRequest={makeRequest}
          editing={editing}
        />
      ) : (
        <EditingFooter
          handleSave={() => handleSave({ playTime: currentValue })}
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}
