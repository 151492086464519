import React, { useContext, useEffect } from 'react'
import { Container, Alert, Card } from 'react-bootstrap'
import AuthContext from '../utils/AuthContext'
import Navigation from '../sections/Navigation'
import lang from '../assets/loadLanguage'
import MyGames from '../sections/dashboard/MyGames'
import Feedback from '../sections/Feedback'
import { InfoCircleFill } from 'react-bootstrap-icons'

function ActiveUser({ user }) {
  return (
    <>
      <Card className="shadow text-center p-3 my-4">
        <p className="mt-3">
          <span>{lang.nextPackDate}</span>
          <strong className="bg-primary text-light p-1 ms-1 rounded text-nowrap">
            {user.dateNextArriveExp}
          </strong>
        </p>
        <p>
          <span>{lang.packageCountText1}</span>
          <strong className="bg-primary text-light p-1 mx-1 rounded">
            {user.packageMax - user.packageCount}
          </strong>
          {lang.packageCountText2}
        </p>
      </Card>
    </>
  )
}

function InactiveUser({ user }) {
  if (user.packageCount > 0) return <ReturningUser />
  else return <Alert variant="warning">{lang.inactiveSubscription}</Alert>
}

function ReturningUser() {
  return (
    <>
      <Alert variant="info text-center">
        <InfoCircleFill className="me-2" />
        {lang.returningUserWelcomeText}
      </Alert>
    </>
  )
}

function Title({ user }) {
  return (
    <h1 className="my-3 text-center">{`${lang.hi}, ${user.firstName}!`}</h1>
  )
}

export default function Dashboard({ updateUser }) {
  const { state } = useContext(AuthContext)
  const { user } = state

  useEffect(async () => {
    await updateUser()
  }, [])

  return (
    <>
      <Navigation />
      <Container className="mb-5">
        <Title user={user} />
        <Feedback />
        {user.status === 'active' && user.dateNext ? (
          <>
            <ActiveUser user={user} />
            <MyGames updateUser={updateUser} />
          </>
        ) : (
          <>
            <InactiveUser user={user} />
            <MyGames updateUser={updateUser} />
          </>
        )}
      </Container>
    </>
  )
}
