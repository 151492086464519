import React, { useState, useContext } from 'react'
import { Button, ToggleButtonGroup, ToggleButton, Modal } from 'react-bootstrap'
import lang from '../assets/loadLanguage'
import api from '../utils/api'
import AuthContext from '../utils/AuthContext'

export default function ReportGameModal({
  game,
  closeReportGameModal,
  reportGameModal,
}) {
  const { dispatch } = useContext(AuthContext)
  const { userNoteSet } = api
  const [reportForm, setReportForm] = useState({
    gameid: game.id,
    note: '',
    compensation: '0',
  })

  function handleChange(...theArgs) {
    const event = theArgs[1] ? theArgs[1] : theArgs[0]
    const { name } = event.target
    const { value } = event.target
    setReportForm({
      ...reportForm,
      [name]: value,
    })
  }

  function handleSave(event) {
    event.preventDefault()
    userNoteSet(
      reportForm.gameid,
      reportForm.note,
      reportForm.compensation
    ).catch(() => {
      dispatch({
        type: 'LOGOUT',
      })
      dispatch({
        type: 'SET_FEEDBACK',
        payload: {
          message: lang.genericError,
          variant: 'danger',
        },
      })
    })
    setReportForm({
      gameid: game.id,
      note: '',
      compensation: '0',
    })
    closeReportGameModal()
  }

  return (
    <Modal
      show={!!reportGameModal}
      onHide={closeReportGameModal}
      animation={false}
    >
      <Modal.Header>
        <Modal.Title>{lang.reportGameModalTitle}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSave} noValidate>
        <Modal.Body>
          <textarea
            className="form-control"
            id="noteInput"
            key={`${game.code}noteInput`}
            name="note"
            value={reportForm.note}
            onChange={handleChange}
            rows="3"
          />
          <h5 className="mt-2">{lang.compensationQuestion}</h5>
          <p className="text-muted">{lang.compensationDescription}</p>
          <div className="text-center">
            <ToggleButtonGroup
              type="radio"
              name="compensation"
              onChange={handleChange}
              value={reportForm.compensation}
              style={{ maxWidth: '20rem' }}
            >
              <ToggleButton
                variant={reportForm.compensation === '1' ? 'primary' : 'light'}
                value="1"
                id="tbg-radio-yes"
              >
                {lang.yesLabel}
              </ToggleButton>
              <ToggleButton
                variant={reportForm.compensation === '0' ? 'primary' : 'light'}
                value="0"
                id="tbg-radio-no"
              >
                {lang.noLabel}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeReportGameModal}>
            {lang.cancelButtonText}
          </Button>
          <Button variant="primary" disabled={!reportForm.note} type="submit">
            {lang.sendButtonText}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
