import React, { useState } from 'react'
import FormHeader from '../../components/FormHeader'
import FormFooter from '../../components/FormFooter'
import {
  Row,
  Col,
  ToggleButton,
  ButtonGroup,
  Container,
  Card,
} from 'react-bootstrap'
import { CameraReels } from 'react-bootstrap-icons'
import lang from '../../assets/loadLanguage'
import EditingFooter from '../../components/EditingFooter'

export default function PreferencesVideoRequired({
  formValues,
  handlePrevStep,
  handleNextStep,
  activeStep,
  stepCount,
  makeRequest,
  editing = false,
  handleSave,
  handleCancel,
}) {
  const [videoRequired, setVideoRequired] = useState(formValues.videoRequired)

  return (
    <>
      {!editing ? (
        <FormHeader
          handleNextStep={() => handleNextStep({ videoRequired })}
          handlePrevStep={() => handlePrevStep({ videoRequired })}
          activeStep={activeStep}
          stepCount={stepCount}
          editing={editing}
        />
      ) : (
        ''
      )}
      <Row>
        <Container>
          <Col />
          <Col sm={12} md={10} lg={8} xl={6} className="m-auto">
            <Card className="py-4 px-3 mt-4 shadow rounded text-center">
              <Row>
                <CameraReels size={40} />
              </Row>
              <Row className="my-3">
                <h4>{lang.videoRequiredTitle}</h4>
              </Row>
              <Row className="mt-2">
                <ButtonGroup className="mb-2">
                  <Col>
                    <ToggleButton
                      className="d-block m-1 unselected"
                      id="radio-videoRequired-1"
                      type="radio"
                      variant={videoRequired === '1' ? 'primary' : 'light'}
                      name="videoRequired"
                      value={'1'}
                      onChange={() => setVideoRequired('1')}
                    >
                      {lang.yesLabel}
                    </ToggleButton>
                  </Col>
                  <Col>
                    <ToggleButton
                      className="d-block m-1 unselected"
                      id="radio-videoRequired-0"
                      type="radio"
                      variant={videoRequired === '0' ? 'primary' : 'light'}
                      name="videoRequired"
                      value={'0'}
                      onChange={() => setVideoRequired('0')}
                    >
                      {lang.noLabel}
                    </ToggleButton>
                  </Col>
                </ButtonGroup>
              </Row>
            </Card>
          </Col>
          <Col />
        </Container>
      </Row>
      {!editing ? (
        <FormFooter
          handleNextStep={() => handleNextStep({ videoRequired })}
          handlePrevStep={() => handlePrevStep({ videoRequired })}
          activeStep={activeStep}
          stepCount={stepCount}
          makeRequest={makeRequest}
          editing={editing}
        />
      ) : (
        <EditingFooter
          handleSave={() => handleSave({ videoRequired: videoRequired })}
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}
