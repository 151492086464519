const initialState = {
  isAuthenticated: !!localStorage.getItem('user'),
  user: JSON.parse(localStorage.getItem('user')) || null,
  alert: null,
  disabledEdit: true,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('user', JSON.stringify(action.payload.user))
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        alert: null,
      }
    case 'LOGOUT':
      localStorage.clear()
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        alert: null,
      }
    case 'CLEAR_FEEDBACK':
      return {
        ...state,
        alert: null,
      }
    case 'SET_FEEDBACK':
      return {
        ...state,
        alert: {
          variant: action.payload.variant,
          message: action.payload.message,
        },
      }
    case 'GET_USER':
      localStorage.setItem('user', JSON.stringify(action.payload.user))
      return {
        ...state,
        user: action.payload.user,
        disabledEdit: action.payload.disabledEdit,
      }
    case 'SET_RATING':
      var newRatingList = state.user.userRatingList.filter(
        (item) => item.gamecode != action.payload.gamecode
      )
      newRatingList.push({
        custid: state.user.custid,
        gamecode: action.payload.gamecode,
        rating: action.payload.rating,
      })
      var newState = { ...state }
      newState.user.userRatingList = [...newRatingList]
      return {
        ...newState,
      }
    default:
      return state
  }
}

export { initialState, reducer }
