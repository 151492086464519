import lang from './loadLanguage'

const playerCountOptions = [
  {
    value: '2',
    label: lang.playerCountOptionLabel2,
  },
  {
    value: '3',
    label: lang.playerCountOptionLabel3,
  },
  {
    value: '4',
    label: lang.playerCountOptionLabel4,
  },
  {
    value: '5',
    label: lang.playerCountOptionLabel5,
  },
  {
    value: '6',
    label: lang.playerCountOptionLabel6,
  },
]

export default playerCountOptions
