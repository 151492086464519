import lang from '../assets/loadLanguage'
import axios from 'axios'

const apiPath = process.env.REACT_APP_API_PATH

// live build const apiLogin = './api/userLogin.php';
const loginEndpoint = `/userLogin.php`
const getUserEndpoint = `/userGet.php`
const apiUserRatingSet = `/userRatingSet.php`
const apiShopInventory = `/shopInventory.php`
const apiShopSell = `/shopSell.php`
const apiGameSellUsed = `/gameSellUsed.php`
const apiUserNoteSet = `/userNoteSet.php`
const apiGameListCode = `/gameListCode.php`
const apiUserCreate = `/userCreate.php`
const apiUserUpdate = `/userUpdate.php`
const apiUserPackageArrive = `/userPackageArrive.php`
const apiAddPack = `/userAddPack.php`
const apiUserNewpwReq = `/userNewpwReq.php`
const apiUserNewpwSet = `/userNewpwSet.php`
const apiOrderCoupon = `/couponSell.php`
/*const apiLogError = `${apiPath}/logError.php`;
const apiSystemStateGet = `${apiPath}/systemStateGet.php`; */

const axiosInstance = axios.create({
  baseURL: apiPath,
})
axiosInstance.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
async function sendRequest(endpoint, data = '') {
  const result = await axiosInstance.post(endpoint, data).catch((error) => {
    if (error?.response?.data[0]) {
      if (error.response.data[0] === 'USER_TIMEOUT')
        throw new Error(lang.timeoutText)
      if (error.response.data[0] === 'STOCK_EMPTY')
        throw new Error(lang.outOfStockText)
      if (error.response.data[0] === 'CUST_EXISTS')
        throw new Error(lang.emailInUse)
      if (error.response.data[0] === 'PW_TIMEOUT')
        throw new Error(lang.newPasswordTimeout)
      if (error.response.data[0] === 'CUST_NOT_FOUND')
        throw new Error(lang.emailNotRegistered)
      if (error.response.data[0] === 'LOGIN_DATA')
        throw new Error(lang.signInError)
    } else throw new Error(lang.genericError)
  })
  if (!result?.data) throw new Error(lang.genericError)
  return result.data
}

const api = {
  async userCreate(updatedProperties) {
    const result = await sendRequest(apiUserCreate, updatedProperties)
    return result
  },
  async userUpdate(user) {
    const result = await sendRequest(apiUserUpdate, user)
    return result
  },
  async userAddPack(productId) {
    const result = await sendRequest(apiAddPack, { productid: productId })
    return result
  },
  async login(email, password) {
    const result = await sendRequest(loginEndpoint, { email, password })
    return result
  },
  async getGames() {
    const result = await sendRequest(apiGameListCode)
    return result
  },
  async getUser() {
    const result = await sendRequest(getUserEndpoint)
    return result
  },
  async gameSellUsed(gameid) {
    const result = await sendRequest(apiGameSellUsed, { id: gameid })
    return result
  },
  async userNoteSet(gameid, note, compensation) {
    const result = await sendRequest(apiUserNoteSet, {
      gameid: gameid,
      note: note,
      compensation: compensation,
    })
    return result
  },
  async userRatingSet(rating) {
    const result = await sendRequest(apiUserRatingSet, rating)
    return result
  },
  async userPackageArrive(pack) {
    const result = await sendRequest(apiUserPackageArrive, pack)
    return result
  },
  async shopInventory() {
    return sendRequest(apiShopInventory).then((data) => {
      const inventory = data[0]
      inventory.forEach((item) => (item.price = parseInt(item.price)))
      return inventory
    })
  },
  async shopSell(cart) {
    return sendRequest(apiShopSell, cart)
  },
  async sendPasswordResetEmail(email) {
    const result = await sendRequest(apiUserNewpwReq, { email })
    return result
  },
  async updatePassword(data) {
    const result = await sendRequest(apiUserNewpwSet, data)
    return result
  },
  async orderCoupon(data) {
    const result = await sendRequest(apiOrderCoupon, data)
    return result
  },
}

export default api
