import React, { useContext, useState, useEffect } from 'react'
import { Badge } from 'react-bootstrap'
import lang from '../../assets/loadLanguage'
import Wishlist from '../signup/Wishlist'
import SummaryRow from '../../components/SummaryRow'
import SummaryCard from '../../components/SummaryCard'
import AuthContext from '../../utils/AuthContext'
import api from '../../utils/api'

export default function WishlistSettings({
  setEditTarget,
  handleSave,
  handleCancel,
}) {
  const { state } = useContext(AuthContext)
  const { user, disabledEdit } = state
  const [games, setGames] = useState([])
  useEffect(() => {
    api
      .getGames()
      .then((data) => setGames(data[0]))
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <SummaryCard title={lang.wishlistLabel}>
      <SummaryRow
        disabledEdit={disabledEdit}
        handleClick={() =>
          setEditTarget(
            <Wishlist
              formValues={user}
              editing={true}
              handleSave={handleSave}
              handleCancel={handleCancel}
              games={games}
            />
          )
        }
      >
        {user.wishlist.length > 0 &&
          user.wishlist.map((game) => (
            <Badge bg={'success'} key={game.code} className="mx-1">
              {game.name}
            </Badge>
          ))}
      </SummaryRow>
    </SummaryCard>
  )
}
