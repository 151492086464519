import React from 'react'
import { Row, Col, Button, Container } from 'react-bootstrap'
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'
import lang from '../assets/loadLanguage'

export default function FormFooter({
  activeStep,
  stepCount,
  handlePrevStep,
  handleNextStep,
  formik = false,
  final = false,
  disabled = false,
}) {
  return (
    <Row className="d-flex align-items-center justify-content-center mt-2 pt-3 pb-3 bg-white">
      {final ? (
        <Container>
          <Row>
            <Col></Col>
            <Col sm={12} md={10} lg={8} xl={6}>
              <Button
                type="submit"
                variant="primary"
                size="lg"
                className="mx-auto mt-2 mb-4 w-100"
                disabled={disabled}
              >
                {lang.submitButtonText}
              </Button>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      ) : (
        <>
          <Col className="d-flex align-items-center justify-content-center">
            {activeStep > 0 && (
              <Button onClick={handlePrevStep} size="lg" variant="primary">
                <ChevronLeft />
              </Button>
            )}
          </Col>
          <Col className="d-flex align-items-center justify-content-center">
            {activeStep < stepCount - 1 && (
              <Button
                onClick={formik ? () => {} : handleNextStep}
                size="lg"
                type={formik ? 'submit' : 'button'}
                variant="primary"
              >
                <ChevronRight />
              </Button>
            )}
          </Col>
        </>
      )}
    </Row>
  )
}
