import React, { useContext, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import lang from '../assets/loadLanguage'
import AuthContext from '../utils/AuthContext'

export default function KeepItModal({
  game,
  gameSellUsed,
  updateUser,
  keepItModal,
  closeKeepItModal,
}) {
  const { dispatch } = useContext(AuthContext)
  const [fetching, setFetching] = useState(false)

  function handleKeepIt() {
    setFetching(true)
    gameSellUsed(game.id)
      .then(() => updateUser())
      .catch((error) => {
        dispatch({
          type: 'LOGOUT',
        })
        dispatch({
          type: 'SET_FEEDBACK',
          payload: {
            message: error.message,
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        setFetching(false)
      })
    closeKeepItModal()
  }

  return (
    <Modal show={!!keepItModal} onHide={closeKeepItModal} animation={false}>
      <Modal.Header>
        <Modal.Title>{lang.keepItModalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{lang.keepItModalText1}</p>
        <p>
          <strong>
            {game.name}
            <br />
            {game.id}-{game.code}
            <br />
            {game.price}
            {lang.priceTag}
            <br />
          </strong>
        </p>
        <p>{lang.paymentDescription}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={closeKeepItModal}
          disabled={fetching}
        >
          {lang.cancelButtonText}
        </Button>
        <Button variant="primary" onClick={handleKeepIt} disabled={fetching}>
          {lang.shopButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
