import React, { useState, useEffect, useContext } from 'react'
import {
  Container,
  Card,
  Col,
  Row,
  Alert,
  Image,
  Button,
} from 'react-bootstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import api from '../utils/api'
import logo from '../assets/logo.png'
import lang from '../assets/loadLanguage'
import Feedback from '../sections/Feedback'
import AuthContext from '../utils/AuthContext'

const LoginSchema = Yup.object().shape({
  email: Yup.string().email(lang.invalidEmail).required(lang.missingValueText)
})

export default function ForgottenPassword() {
  const [formError, setFormError] = useState('')
  const [message, setMessage] = useState('');
  const { dispatch } = useContext(AuthContext)

  useEffect(() => {    
    dispatch({
      type: 'CLEAR_FEEDBACK',
    })
  }, [])

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setFormError('')
    setMessage('');
    api
      .sendPasswordResetEmail(values.email)
      .then(() => {
        setMessage(lang.resetPasswordDone);
        resetForm()
        setSubmitting(false)
      })
      .catch((error) => {
        setFormError(error.message)
        setSubmitting(false)
      })
  }
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={8} md={6} xl={4}>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={LoginSchema}
            onSubmit={handleSubmit}
          >
            {({ touched, errors, isSubmitting }) => (
              <Card className="p-3 my-3 shadow rounded">
                <Form noValidate>
                  <Row>
                    <Col />
                    <Col xs={6}>
                      <Image className="align-self-center" src={logo} fluid />
                    </Col>
                    <Col />
                  </Row>
                  <h2 className="text-center mb-4">{lang.forgottenPasswordTitle}</h2>
                  <Feedback fullWidth={true} />
                  {formError && <Alert variant="danger">{formError}</Alert>}
                  {message && <Alert variant="success">{message}</Alert>}
                  <div className="form-group mb-3">
                    <label htmlFor="email">{lang.emailLabel}</label>
                    <Field
                      type="email"
                      name="email"
                      className={`form-control ${
                        touched.email && errors.email ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="email"
                      className="invalid-feedback"
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 my-2 mx-auto"
                    disabled={isSubmitting}
                  >
                    {lang.newPasswordButton}
                  </Button>                  
                  <div className="text-center">
                  <Link to="/login" className="text-center">{lang.loginButtonText}</Link>
                  </div>
                </Form>
              </Card>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  )
}
