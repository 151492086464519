import React, { useContext } from 'react'
import { Alert, Col, Container } from 'react-bootstrap'
import AuthContext from '../utils/AuthContext'

export default function Feedback({ fullWidth = false }) {
  const { dispatch, state } = useContext(AuthContext)

  return (
    <>
      {state?.alert?.message && (
        <Container className="p-0">
          <Col
            xs={12}
            sm={fullWidth ? 12 : 10}
            md={fullWidth ? 12 : 8}
            xl={fullWidth ? 12 : 6}
            className="m-auto"
          >
            <Alert
              variant={state.alert.variant}
              onClose={() =>
                dispatch({
                  type: 'CLEAR_FEEDBACK',
                })
              }
              dismissible
            >
              {state.alert.message}
            </Alert>
          </Col>
        </Container>
      )}
    </>
  )
}
