import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import lang from '../../assets/loadLanguage'
import { Row, Container, Col, Card } from 'react-bootstrap'
import * as Yup from 'yup'
import { CardChecklist } from 'react-bootstrap-icons'
import EditingFooter from '../../components/EditingFooter'
import Select from 'react-select'
import FocusError from '../../utils/FocusError'

const signupSchema = Yup.object().shape({
  firstName: Yup.string().required(lang.missingValueText),
  lastName: Yup.string().required(lang.missingValueText),
  phone: Yup.string()
    .required(lang.missingValueText)
    .matches(/^\d+$/, lang.invalidFormat)
    .min(9, lang.invalidFormat)
    .max(9, lang.invalidFormat),
  postcode: Yup.string()
    .required(lang.missingValueText)
    .matches(/^\d+$/, lang.invalidFormat)
    .min(4, lang.invalidFormat)
    .max(4, lang.invalidFormat),
  city: Yup.string().required(lang.missingValueText),
  addr1: Yup.string().required(lang.missingValueText),
})

function InputField({ touched, errors, fieldName, pw = false }) {
  return (
    <div className="form-group mb-2">
      <label htmlFor={fieldName}>{lang[fieldName + 'Label']}</label>
      <Field
        type={pw ? 'password' : 'text'}
        name={fieldName}
        className={`form-control ${
          touched[fieldName] && errors[fieldName] ? 'is-invalid' : ''
        }`}
      />
      <ErrorMessage
        component="div"
        name={fieldName}
        className="invalid-feedback"
      />
    </div>
  )
}

export default function AddressEdit({
  formValues,
  handleSave,
  handleCancel,
  foxpostList,
}) {
  async function handleSubmit(values) {
    handleSave({ ...values, foxpostMachine })
  }

  const [foxpostMachine, setFoxpostMachine] = useState(
    formValues.foxpostMachine
  )

  return (
    <Formik
      initialValues={formValues}
      onSubmit={handleSubmit}
      validationSchema={signupSchema}
    >
      {({ touched, errors }) => (
        <Form noValidate>
          <FocusError />
          <Row>
            <Container>
              <Col />
              <Col sm={12} md={10} lg={8} xl={6} className="m-auto">
                <Card className="py-4 px-3 mt-4 shadow rounded">
                  <Row>
                    <CardChecklist size={40} />
                  </Row>
                  <Row className="mt-3 text-center">
                    <h4>{lang.addressTitle}</h4>
                  </Row>
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="lastName"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="firstName"
                  />
                  <div className="form-group mb-2">
                    <label htmlFor="phone">{lang.phoneLabel}</label>
                    <Row>
                      <Col className="pe-0" style={{ maxWidth: '4rem' }}>
                        <span
                          className="input-group-text ps-1"
                          id="phone-prefix"
                        >
                          {lang.phonePrefix}
                        </span>
                      </Col>
                      <Col className="ps-0">
                        <Field
                          type="tel"
                          name="phone"
                          className={`form-control w-100 ${
                            touched.phone && errors.phone ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="phone"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                  </div>
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="postcode"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="city"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="addr1"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="addr2"
                  />
                  <InputField
                    touched={touched}
                    errors={errors}
                    fieldName="addrNote"
                  />
                  {formValues.courier === 'Foxpost' && (
                    <>
                      {lang.foxpostLabel}
                      <Select
                        options={foxpostList}
                        onChange={(item) => setFoxpostMachine(item.value)}
                        search
                        name="foxpostMachine"
                        value={foxpostList.find(
                          (item) => item.value === foxpostMachine
                        )}
                      />
                      <a
                        href="https://foxpost.hu/csomagautomatak"
                        target="_blank"
                        rel="noreferrer"
                        className="mt-3"
                      >
                        {lang.foxpostLinkText}
                      </a>
                    </>
                  )}
                </Card>
              </Col>
              <Col />
            </Container>
          </Row>
          <EditingFooter
            handleSave={() => {}}
            handleCancel={handleCancel}
            setSubmit={true}
          />
          <Row className="my-5"></Row>
        </Form>
      )}
    </Formik>
  )
}
