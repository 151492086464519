import React from 'react'
import { Row, Button, Container, Col } from 'react-bootstrap'
import lang from '../assets/loadLanguage'

export default function EditingFooter({
  handleSave,
  handleCancel,
  setSubmit = false,
}) {

  return (
    <Row className="d-flex align-items-center justify-content-center mt-2 pt-3 pb-3 bg-white">
      <Container>
        <Row>
          <Col className="text-center">
            <Button type="button" variant="secondary" onClick={handleCancel}>
              {lang.cancelButtonText}
            </Button>
          </Col>
          <Col className="text-center">
            <Button
              type={setSubmit ? 'submit' : 'button'}
              variant="primary"
              onClick={handleSave}
            >
              {lang.saveButtonText}
            </Button>
          </Col>
        </Row>
      </Container>
    </Row>
  )
}
