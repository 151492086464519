import React, { useState } from 'react'
import FormHeader from '../../components/FormHeader'
import FormFooter from '../../components/FormFooter'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { Star } from 'react-bootstrap-icons'
import lang from '../../assets/loadLanguage'
import EditingFooter from '../../components/EditingFooter'

export default function PreferencesFavourites({
  formValues,
  handlePrevStep,
  handleNextStep,
  activeStep,
  stepCount,
  makeRequest,
  editing = false,
  handleSave,
  handleCancel,
}) {
  const [favourites, setFavourites] = useState(formValues.favourites)

  return (
    <>
      {!editing ? (
        <FormHeader
          handleNextStep={() => handleNextStep({ favourites })}
          handlePrevStep={() => handlePrevStep({ favourites })}
          activeStep={activeStep}
          stepCount={stepCount}
          editing={editing}
        />
      ) : (
        ''
      )}
      <Row>
        <Container>
          <Col />
          <Col sm={12} md={10} lg={8} xl={6} className="m-auto">
            <Card className="py-4 px-3 mt-4 shadow rounded text-center">
              <Row>
                <Star size={40} />
              </Row>
              <Row className="my-3">
                <h4>{lang.favouritesTitle}</h4>
              </Row>
              <Row className="mt-2">
                <Container>
                  <textarea
                    maxLength="800"
                    className="w-100"
                    rows="7"
                    value={favourites}
                    onChange={(e) => setFavourites(e.target.value)}
                  ></textarea>
                </Container>
              </Row>
            </Card>
          </Col>
          <Col />
        </Container>
      </Row>
      {!editing ? (
        <FormFooter
          handleNextStep={() => handleNextStep({ favourites })}
          handlePrevStep={() => handlePrevStep({ favourites })}
          activeStep={activeStep}
          stepCount={stepCount}
          makeRequest={makeRequest}
          editing={editing}
        />
      ) : (
        <EditingFooter
          handleSave={() => handleSave({ favourites: favourites })}
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}
