import React, { useState } from 'react'
import FormHeader from '../../components/FormHeader'
import FormFooter from '../../components/FormFooter'
import {
  Row,
  Col,
  ToggleButton,
  ButtonGroup,
  Container,
  Card,
} from 'react-bootstrap'
import { Dice2 } from 'react-bootstrap-icons'
import lang from '../../assets/loadLanguage'
import EditingFooter from '../../components/EditingFooter'

export default function PreferencesChildren({
  formValues,
  handlePrevStep,
  handleNextStep,
  activeStep,
  stepCount,
  makeRequest,
  editing = false,
  handleSave,
  handleCancel,
}) {
  const [children, setChildren] = useState(formValues.children)
  const [childrenAge, setChildrenAge] = useState(formValues.childrenAge)
  const [childrenType, setChildrenType] = useState(formValues.childrenType)

  return (
    <>
      {!editing ? (
        <FormHeader
          handleNextStep={() =>
            handleNextStep({ children, childrenAge, childrenType })
          }
          handlePrevStep={() =>
            handlePrevStep({ children, childrenAge, childrenType })
          }
          activeStep={activeStep}
          stepCount={stepCount}
          editing={editing}
        />
      ) : (
        ''
      )}
      <Row>
        <Container>
          <Col />
          <Col sm={12} md={10} lg={8} xl={6} className="m-auto">
            <Card className="py-4 px-3 mt-4 shadow rounded text-center">
              <Row>
                <Dice2 size={40} />
              </Row>
              <Row className="my-3">
                <h4>{lang.childrenTitle}</h4>
              </Row>
              <Row className="mt-2">
                <ButtonGroup className="mb-2">
                  <Col>
                    <ToggleButton
                      className="d-block m-1 unselected"
                      id="radio-children-1"
                      type="radio"
                      variant={children === '1' ? 'primary' : 'light'}
                      name="children"
                      value={'1'}
                      onChange={() => setChildren('1')}
                    >
                      {lang.yesLabel}
                    </ToggleButton>
                  </Col>
                  <Col>
                    <ToggleButton
                      className="d-block m-1 unselected"
                      id="radio-children-0"
                      type="radio"
                      variant={children === '0' ? 'primary' : 'light'}
                      name="children"
                      value={'0'}
                      onChange={() => setChildren('0')}
                    >
                      {lang.noLabel}
                    </ToggleButton>
                  </Col>
                </ButtonGroup>
              </Row>
              {children === '1' && (
                <>
                  <Row className="mt-2">
                    <ButtonGroup className="mb-2">
                      <Col xs={6}>
                        <ToggleButton
                          className="d-block m-1 unselected"
                          id="radio-childrenType-1"
                          type="radio"
                          variant={childrenType === '1' ? 'primary' : 'light'}
                          name="childrenType"
                          value={'1'}
                          onChange={() => setChildrenType('1')}
                          disabled={children === '0'}
                        >
                          {lang.childrenTypeLabel1}
                        </ToggleButton>
                      </Col>
                      <Col xs={6}>
                        <ToggleButton
                          className="d-block m-1 unselected"
                          id="radio-childrenType-0"
                          type="radio"
                          variant={childrenType === '0' ? 'primary' : 'light'}
                          name="childrenType"
                          value={'0'}
                          onChange={() => setChildrenType('0')}
                          disabled={children === '0'}
                        >
                          {lang.childrenTypeLabel2}
                        </ToggleButton>
                      </Col>
                    </ButtonGroup>
                  </Row>
                  <Row className="mt-2">
                    <ButtonGroup className="mb-2">
                      <Col>
                        <ToggleButton
                          className="d-block m-1 unselected"
                          id="radio-childrenAge-1"
                          type="radio"
                          variant={childrenAge === '1' ? 'primary' : 'light'}
                          name="childrenAge"
                          value={'1'}
                          onChange={() => setChildrenAge('1')}
                          disabled={children === '0'}
                        >
                          {lang.childrenAgeLabel1}
                        </ToggleButton>
                      </Col>
                      <Col>
                        <ToggleButton
                          className="d-block m-1 unselected"
                          id="radio-childrenAge-0"
                          type="radio"
                          variant={childrenAge === '0' ? 'primary' : 'light'}
                          name="childrenAge"
                          value={'0'}
                          onChange={() => setChildrenAge('0')}
                          disabled={children === '0'}
                        >
                          {lang.childrenAgeLabel2}
                        </ToggleButton>
                      </Col>
                    </ButtonGroup>
                  </Row>
                </>
              )}
            </Card>
          </Col>
          <Col />
        </Container>
      </Row>
      {!editing ? (
        <FormFooter
          handleNextStep={() =>
            handleNextStep({ children, childrenAge, childrenType })
          }
          handlePrevStep={() =>
            handlePrevStep({ children, childrenAge, childrenType })
          }
          activeStep={activeStep}
          stepCount={stepCount}
          makeRequest={makeRequest}
          editing={editing}
        />
      ) : (
        <EditingFooter
          handleSave={() =>
            handleSave({
              children: children,
              childrenAge: childrenAge,
              childrenType: childrenType,
            })
          }
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}
