import React from 'react'
import { Col, Card } from 'react-bootstrap'

export default function SummaryCard({ title, children }) {
  return (
    <Col className="mt-2">
      <Card className="shadow rounded">
        <Card.Header>
          <h3 className="text-center">{title}</h3>
        </Card.Header>
        <Card.Body>{children}</Card.Body>
      </Card>
    </Col>
  )
}
