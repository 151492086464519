import React from 'react'
import { Col, Container, Row, Badge } from 'react-bootstrap'
import { X } from 'react-bootstrap-icons'
import lang from '../assets/loadLanguage'

export default function SelectedGameList({
  list,
  onClick,
  background,
  label,
  shop = false,
}) {
  return (
    <>
      {list.length > 0 && (
        <Container>
          <Row>
            <Col></Col>
            <Col sm={12} md={10} lg={8} xl={6}>
              {!shop && <strong>{label}:</strong>}
              {list.map((game, index) => (
                <Badge
                  bg={background}
                  key={game.code + index}
                  onClick={() => onClick(game)}
                  className="mx-1"
                >
                  {game.name}
                  {shop && ` -  ${game.price}${lang.priceTag}`}
                  <X size={18} />
                </Badge>
              ))}
            </Col>
            <Col></Col>
          </Row>
        </Container>
      )}
    </>
  )
}
