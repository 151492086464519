import React, { useState, useRef } from 'react'
import { Container, Row, Alert, Col } from 'react-bootstrap'
import lang from '../../assets/loadLanguage'
import GameList from '../../components/GameList'
import SelectedGameList from '../../components/SelectedGameList'
import FormHeader from '../../components/FormHeader'
import FormFooter from '../../components/FormFooter'
import EditingFooter from '../../components/EditingFooter'
import { InfoCircleFill } from 'react-bootstrap-icons'

export default function Wishlist({
  formValues,
  handlePrevStep,
  handleNextStep,
  activeStep,
  stepCount,
  makeRequest,
  editing = false,
  handleSave,
  handleCancel,
  games,
}) {
  const [wishlist, setWishlist] = useState(formValues.wishlist)
  const selectedGamesRef = useRef(null)

  function addToWishlist(game) {
    if (wishlist.length === 0)
      selectedGamesRef.current.scrollIntoView({ behavior: 'smooth' })
    setWishlist((prev) => [...prev, game])
  }
  function removeFromWishlist(game) {
    const newWishlist = [...wishlist]
    const index = wishlist.indexOf(game)
    newWishlist.splice(index, 1)
    setWishlist([...newWishlist])
  }

  return (
    <>
      {!editing ? (
        <FormHeader
          handleNextStep={() => handleNextStep({ wishlist })}
          handlePrevStep={() => handlePrevStep({ wishlist })}
          activeStep={activeStep}
          stepCount={stepCount}
          editing={editing}
        />
      ) : (
        ''
      )}
      <Container className="text-center">
        <Row className="my-3">
          <h4>{lang.wishlistTitle}</h4>
        </Row>
        <Row>
          <Col />
          <Col sm={12} md={12} lg={10} xl={8} className="m-auto">
            <p>
              <strong>{lang.wishlistHelpText1}</strong> {lang.wishlistHelpText2}
            </p>
            <p>
              <strong> {lang.wishlistHelpText3}</strong>{' '}
              {lang.wishlistHelpText4}
            </p>
            <Alert className="mt-3" variant="info">
              <InfoCircleFill className="me-2" />
              {lang.wishlistHelpText6}
              {editing &&
                <>
                  <br /><br />{lang.wishlistHelpText7}
                </>}
            </Alert>
          </Col>
          <Col />
        </Row>
        <Row ref={selectedGamesRef}>
          <SelectedGameList
            list={wishlist}
            onClick={removeFromWishlist}
            background="success"
            label={lang.wishlistLabel}
          />
        </Row>
        <GameList
          games={games}
          excludeList={wishlist.concat(formValues.unwanted)}
          onClick={addToWishlist}
        />
      </Container>
      <div className="fixed-bottom">
        {!editing ? (
          <FormFooter
            handleNextStep={() => handleNextStep({ wishlist })}
            handlePrevStep={() => handlePrevStep({ wishlist })}
            activeStep={activeStep}
            stepCount={stepCount}
            makeRequest={makeRequest}
            editing={editing}
          />
        ) : (
          <EditingFooter
            handleSave={() => {
              handleSave({
                wishlist: wishlist,
              })
            }}
            handleCancel={handleCancel}
          />
        )}
      </div>
    </>
  )
}
