import React, { useState, useEffect, useContext } from 'react'
import {
  Button,
  Container,
  Row,
  Modal,
  Badge,
  Col,
  Alert,
} from 'react-bootstrap'
import { Cart4, InfoCircleFill } from 'react-bootstrap-icons'
import Navigation from '../sections/Navigation'
import lang from '../assets/loadLanguage'
import GameList from '../components/GameList'
import SelectedGameList from '../components/SelectedGameList'
import MessageModal from '../components/MessageModal'
import api from '../utils/api'
import AuthContext from '../utils/AuthContext'
import Feedback from '../sections/Feedback'

export default function Shop() {
  const { shopInventory, shopSell } = api
  const { dispatch, state } = useContext(AuthContext)
  const { user } = state

  const [inventory, setInventory] = useState([])
  const [cart, setCart] = useState([])
  const [sum, setSum] = useState(0)

  const [cartModal, setCartModal] = useState(false)
  function toggleCart() {
    setCartModal((cartModal) => !cartModal)
  }

  const [messageModal, setMessageModal] = useState(false)
  function toggleMessageModal() {
    setMessageModal((messageModal) => !messageModal)
  }

  useEffect(() => {
    shopInventory()
      .then((inv) => setInventory(inv))
      .catch((error) => {
        dispatch({
          type: 'LOGOUT',
        })
        dispatch({
          type: 'SET_FEEDBACK',
          payload: {
            message: error.message,
            variant: 'danger',
          },
        })
      })
  }, [])

  function addToCart(item) {
    setCart([...cart, item])
  }

  function removeFromCart(game) {
    const newCart = [...cart]
    const index = cart.indexOf(game)
    newCart.splice(index, 1)
    setCart([...newCart])
  }

  useEffect(() => {
    setSum(
      cart.reduce(
        (accumulator, currentValue) => accumulator + currentValue.price,
        0
      )
    )
  }, [cart])

  function sendOrder() {
    shopSell(cart)
      .then(() => {
        setCart([])
        toggleCart()
        toggleMessageModal()
      })
      .catch((error) => {
        if (error.message === lang.outOfStockText) {
          setCart([])
          toggleCart()
          dispatch({
            type: 'SET_FEEDBACK',
            payload: {
              message: lang.outOfStockText,
              variant: 'danger',
            },
          })
        } else {
          dispatch({
            type: 'LOGOUT',
          })
          dispatch({
            type: 'SET_FEEDBACK',
            payload: {
              message: lang.genericError,
              variant: 'danger',
            },
          })
        }
      })
  }

  const shop = {
    purchaseButton: (game) => (
      <>
        <Row className="w-100 mt-2 text-center mx-auto">
          <span>{`${lang.priceText}: ${game.price}${lang.priceTag}`}</span>
        </Row>
        <Button className="w-100" onClick={() => addToCart(game)}>
          {lang.addToCartText}
        </Button>
      </>
    ),
  }

  function CartModal() {
    return (
      <Modal show={cartModal} onHide={toggleCart} animation={false}>
        <Modal.Header>
          <Modal.Title>{lang.cartTitleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{lang.cartText}</p>
          <p>{lang.cartNote}</p>
          <SelectedGameList list={cart} onClick={removeFromCart} shop={true} />
          <p>{`${lang.sumText}: ${sum}${lang.priceTag}`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleCart}>
            {lang.cancelButtonText}
          </Button>
          <Button
            variant="primary"
            onClick={sendOrder}
            disabled={cart.length === 0}
          >
            {lang.orderButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  function CartButton() {
    return (
      <Container className="my-3 text-end">
        <Button
          size="lg"
          variant="outline-primary"
          className="position-relative shadow-lg p-3 cart"
          onClick={toggleCart}
        >
          <Cart4 size={28} />
          <Badge
            pill
            bg="light"
            className="border border-primary text-primary position-absolute top-0 start-100 translate-middle"
          >
            {cart.length}
          </Badge>
        </Button>
      </Container>
    )
  }

  return (
    <>
      <Navigation />
      <Container>
        {user.packageMax - user.packageCount ? (
          <>
            <CartModal />
            <MessageModal
              message={lang.orderConfirmMessage}
              show={messageModal}
              toggleShow={toggleMessageModal}
            />
            <Row className="me-1 my-3 text-center">
              <Col xs={8} md={7}>
                <h1 className="my-3 text-end">{lang.shopTitle}</h1>
              </Col>
              <Col xs={4} md={5}>
                <CartButton className=""/>
              </Col>
            </Row>
            <Feedback />
            {inventory && (
              <GameList games={inventory} excludeList={cart} shop={shop} />
            )}
          </>
        ) : (
          <Row className="m-auto text-center">
              <Alert variant="info">
                <InfoCircleFill className="me-2" />
                {lang.shopDisabledInfo}
              </Alert>
          </Row>
        )}
      </Container>
    </>
  )
}
