import lang from './loadLanguage'

const difficultyOptions = [
  {
    value: '1',
    label: lang.difficultyLabel1,
  },
  {
    value: '2',
    label: lang.difficultyLabel2,
  },
  {
    value: '3',
    label: lang.difficultyLabel3,
  },
  {
    value: '4',
    label: lang.difficultyLabel4,
  },
]

export default difficultyOptions
