import React, { useState, useContext } from 'react'
import AuthContext from '../../utils/AuthContext'
import { Row, Col, Button } from 'react-bootstrap'
import AddPackModal from '../../components/AddPackModal'
import lang from '../../assets/loadLanguage'
import SummaryCard from '../../components/SummaryCard'
import { Alert } from 'react-bootstrap'
import { InfoCircleFill } from 'react-bootstrap-icons'

export default function AddPack({toggleMessageModal}) {
  const { state } = useContext(AuthContext)
  const { user, disabledEdit } = state

  const [addPackModal, setAddPackModal] = useState(false)
  const closeAddPackModal = () => setAddPackModal(false)
  const showAddPackModal = ({ productId, packagePrice }) =>
    setAddPackModal({ productId, packagePrice })

  const activeUser = user.status === 'active' && user.dateNext
  const disabledProduct5 = user.packageCount > 0 && !activeUser && user.courier !== 'Foxpost'

  const disableAddPack = user.courier !== 'Foxpost'
  const missingFoxpostMachine = user.courier === 'Foxpost' && user.foxpostMachine === ''

  return (
    <SummaryCard
      title={activeUser ? lang.addPackTitle : lang.newPackTitle}
    >
      {addPackModal && (
        <AddPackModal
          addPackModal={addPackModal}
          closeAddPackModal={closeAddPackModal}
          toggleMessageModal={toggleMessageModal}
        />
      )}
      <Row className="text-center">
        <p>{activeUser ? lang.addPackDescription : lang.productDescription}</p>
        <Col>
          <Button
            disabled={disabledEdit || disabledProduct5 || disableAddPack || missingFoxpostMachine}
            className="mx-1"
            onClick={() =>
              showAddPackModal({
                productId: 5,
                packagePrice: activeUser
                  ? lang.onePackPriceDiscount
                  : lang.onePackPrice,
              })
            }
          >
            <p>
              {activeUser && '+'}
              {lang.onePackLabel}
            </p>
            <small>
              {activeUser ? lang.onePackPriceDiscount : lang.onePackPrice}
            </small>
          </Button>
          <Button
            disabled={disabledEdit || disableAddPack || missingFoxpostMachine}
            className="mx-1"
            onClick={() =>
              showAddPackModal({
                productId: 3,
                packagePrice: activeUser
                  ? lang.threePackPriceDiscount
                  : lang.threePackPrice,
              })
            }
          >
            <p>
              {activeUser && '+'}
              {lang.threePackLabel}
            </p>
            <small>
              {activeUser ? lang.threePackPriceDiscount : lang.threePackPrice}
            </small>
          </Button>
          {disableAddPack && 
          <Alert variant="info" className="mt-3">
            <InfoCircleFill className="me-2" />
            {lang.disabledAddPackInfo}
          </Alert>}
          {missingFoxpostMachine && 
          <Alert variant="info" className="mt-3">
            <InfoCircleFill className="me-2" />
            {lang.chooseFoxpostMachine}
          </Alert>}
          {disabledProduct5 && !disableAddPack && 
          <Alert variant="info" className="mt-3">
            <InfoCircleFill className="me-2" />
            {lang.disabledProduct5Info}
          </Alert>}
        </Col>
      </Row>
    </SummaryCard>
  )
}
