import React from 'react'
import { ToggleButton, Card, Row, Col, Container } from 'react-bootstrap'

export default function FormToggleButtonGroup({
  currentValue,
  handleChange,
  options,
  title,
  getIcon,
  helpText,
}) {
  return (
    <Container>
      <Col />
      <Col sm={12} md={10} lg={8} xl={6} className="m-auto">
        <Card className="py-4 px-5 mt-4 shadow rounded text-center">
          <Row>{getIcon()}</Row>
          <Row className="mt-3 mb-3">
            <h4 className="mb-1">{title}</h4>
            <small>{helpText}</small>
          </Row>
          <Row>
            {options.map((option, index) => (
              <Row key={option.value} className="m-auto mt-2">
                <ToggleButton
                  className={
                    currentValue.includes(option.value)
                      ? 'py-2 toggle-button active'
                      : 'py-2 toggle-button'
                  }
                  type="checkbox"
                  value={option.value}
                  checked={currentValue.includes(option.value)}
                  onChange={handleChange}
                  variant="light"
                  id={`tbg-btn-${index}`}
                >
                  {option.label}
                </ToggleButton>
              </Row>
            ))}
          </Row>
        </Card>
      </Col>
      <Col />
    </Container>
  )
}
