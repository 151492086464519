import React, { useContext, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import lang from '../assets/loadLanguage'
import AuthContext from '../utils/AuthContext'
import api from '../utils/api'

export default function AddPackModal({
  addPackModal,
  closeAddPackModal,
  toggleMessageModal,
}) {
  const { dispatch } = useContext(AuthContext)
  const { productId, packagePrice } = addPackModal
  const [fetching, setFetching] = useState(false)

  function handleAddPack() {
    setFetching(true)
    api
      .userAddPack(productId)
      .then(() => {
        closeAddPackModal()
        toggleMessageModal()
      })
      .catch(() => {
        dispatch({
          type: 'LOGOUT',
        })
        dispatch({
          type: 'SET_FEEDBACK',
          payload: {
            message: lang.genericError,
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        setFetching(false)
      })
  }

  return (
    <Modal show={!!addPackModal} onHide={closeAddPackModal} animation={false}>
      <Modal.Header>
        <Modal.Title>{lang.addPackTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>{`${
            productId === 3
              ? lang.addPackDescThreePack
              : lang.addPackDescOnePack
          }${packagePrice}`}</strong>
        </p>
        <p>{lang.paymentDescription}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeAddPackModal} disabled={fetching}>
          {lang.cancelButtonText}
        </Button>
        <Button variant="primary" onClick={handleAddPack} disabled={fetching}>
          {lang.shopButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
