import React, { useContext, useEffect, useState } from 'react'
import lang from '../../assets/loadLanguage'
import AuthContext from '../../utils/AuthContext'
import AddressEdit from '../signup/AddressEdit'
import SummaryRow from '../../components/SummaryRow'
import SummaryCard from '../../components/SummaryCard'

export default function AddressSettings({
  setEditTarget,
  handleSave,
  handleCancel,
}) {
  const { state } = useContext(AuthContext)
  const { user, disabledEdit } = state
  const [selectedMachine, setSelectedMachine] = useState(user.foxpostMachine)
  const [foxpostError, setFoxpostError] = useState('')

  // Fetching Foxpost machine list from FoxpostCDN
  const [foxpostList, setFoxpostList] = useState([])
  useEffect(() => {
    if (user.courier === 'Foxpost') {
      const foxpostMachines = []
      fetch('./foxpostMachines.json', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          data.forEach((item) =>
            foxpostMachines.push({
              label: `${item.name} - ${item.address}`,
              value: item.operator_id,
            })
          )
          setFoxpostList(foxpostMachines)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [])

  useEffect(() => {
    if (foxpostList.length > 0) {
      setFoxpostError('')
      const machine = foxpostList.find(
        (item) => item.value === user.foxpostMachine
      )
      if (machine) {
        setSelectedMachine(machine.label)
      } else {
        setFoxpostError(lang.missingFoxpostInput)
      }
    }
  }, [foxpostList, user.foxpostMachine])

  return (
    <SummaryCard title={lang.addressTitle}>
      <SummaryRow
        disabledEdit={disabledEdit}
        handleClick={() =>
          setEditTarget(
            <AddressEdit
              formValues={user}
              handleSave={handleSave}
              handleCancel={handleCancel}
              foxpostList={foxpostList}
            />
          )
        }
      >
        <p>
          {`${lang.lastNameLabel}: `}
          <strong>{user.lastName}</strong>
        </p>
        <p>
          {`${lang.firstNameLabel}: `}
          <strong>{user.firstName}</strong>
        </p>
        <p>
          {`${lang.emailLabel}: `}
          <strong>{user.email}</strong>
        </p>
        <p>
          {`${lang.phoneLabel}: `}
          <strong>+36{user.phone}</strong>
        </p>
        <p>
          {`${lang.postcodeLabel}: `}
          <strong>{user.postcode}</strong>
        </p>
        <p>
          {`${lang.cityLabel}: `}
          <strong> {user.city}</strong>
        </p>
        <p>
          {`${lang.addr1Label}: `}
          <strong>{user.addr1}</strong>
        </p>
        <p>
          {`${lang.addr2Label}: `}
          <strong>{user.addr2}</strong>
        </p>
        <p>
          {`${lang.addrNoteLabel}: `}
          <strong>{user.addrNote}</strong>
        </p>
        {user.courier === 'Foxpost' && (
          <>
            <p>
              {`${lang.foxpostLabel}: `}
              <strong>{selectedMachine}</strong>
            </p>
            {foxpostError && (
              <div className="invalid-feedback d-block">{foxpostError}</div>
            )}
          </>
        )}
      </SummaryRow>
    </SummaryCard>
  )
}
