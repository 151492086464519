import React, { useState } from 'react'
import {
  Container,
  Card,
  Col,
  Row,
  Alert,
  Image,
  Button,
} from 'react-bootstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Link, useParams } from 'react-router-dom'
import api from '../utils/api'
import logo from '../assets/logo.png'
import lang from '../assets/loadLanguage'
import Feedback from '../sections/Feedback'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, lang.invalidPasswordLength)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])/, lang.invalidPasswordFormat)
    .required(lang.missingValueText),
  passwordConfirm: Yup.string()
    .min(5, lang.invalidPasswordLength)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])/, lang.invalidPasswordFormat)
    .required(lang.missingValueText)
    .oneOf([Yup.ref('password'), null], lang.passwordsDontMatchText),
})

function ResetPasswordForm({ token }) {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [formError, setFormError] = useState('')
  const [message, setMessage] = useState('')
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setFormError('')
    setMessage('')
    const tokenCaptcha = await executeRecaptcha('passwordReset')
    api
      .updatePassword({
        password: values.password,
        token: token,
        tokenCaptcha: tokenCaptcha,
      })
      .then(() => {
        setMessage(lang.passwordHasBeenUpdatedText)
        resetForm()
        setSubmitting(false)
      })
      .catch((error) => {
        setFormError(error.message)
        setSubmitting(false)
      })
  }
  return (
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} sm={8} md={6} xl={4}>
            <Formik
              initialValues={{ password: '', passwordConfirm: '' }}
              validationSchema={LoginSchema}
              onSubmit={handleSubmit}
            >
              {({ touched, errors, isSubmitting }) => (
                <Card className="p-3 my-3 shadow rounded">
                  <Form noValidate>
                    <Row>
                      <Col />
                      <Col xs={6}>
                        <Image className="align-self-center" src={logo} fluid />
                      </Col>
                      <Col />
                    </Row>
                    <h2 className="text-center mb-4">
                      {lang.forgottenPasswordTitle}
                    </h2>
                    <Feedback fullWidth={true} />
                    {formError && <Alert variant="danger">{formError}</Alert>}
                    {message && <Alert variant="success">{message}</Alert>}
                    <div className="form-group mb-3">
                      <label htmlFor="password">{lang.passwordLabel}</label>
                      <Field
                        type="password"
                        name="password"
                        className={`form-control ${
                          touched.password && errors.password
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="password"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="passwordConfirm">
                        {lang.passwordConfirmLabel}
                      </label>
                      <Field
                        type="password"
                        name="passwordConfirm"
                        className={`form-control ${
                          touched.passwordConfirm && errors.passwordConfirm
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="passwordConfirm"
                        className="invalid-feedback"
                      />
                    </div>
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-100 my-2 mx-auto"
                      disabled={isSubmitting}
                    >
                      {lang.saveButtonText}
                    </Button>
                    <div className="text-center">
                      <Link to="/login" className="text-center">
                        {lang.loginButtonText}
                      </Link>
                    </div>
                  </Form>
                </Card>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
  )
}


export default function ResetPassword() {
  let { token } = useParams()
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <ResetPasswordForm token={token}/>
    </GoogleReCaptchaProvider>
  )
}

